import { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card, { NftCardSkelton } from "../card";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ActivityTable from "../artistActivityTable";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CusTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const {
    userInWallet: { nfts, orders },
    isUserInWalletGettingSuccess,
    isUserInWalletGetting,
  } = useSelector((state) => state.userProfile);

  const { walletAddress } = useSelector((state) => state.auth.user);
  const { isAdmin, connectedWallet } = useSelector(
    (state) => state.walletSlicer
  );

  const { wallet } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabsContainer}
        value={value}
        onChange={handleChange}
      >
        <Tab label="IN WALLET" {...a11yProps(0)} maxWidth={false} />

        <Tab label="ACTIVITY" {...a11yProps(1)} maxWidth={false} />

        {walletAddress === wallet && isAdmin && (
          <Tab label="ORDERS" {...a11yProps(2)} maxWidth={false} />
        )}
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabPanel}>
        {isUserInWalletGettingSuccess && nfts.length === 0 ? (
          <Typography variant="h2" align="center" style={{ color: "white" }}>
            No Arts To Show!
          </Typography>
        ) : (
          <div className={classes.tabNft}>
            <InWalletSection />
          </div>
        )}
      </TabPanel>

      <TabPanel value={value} index={1} className={classes.tabPanel}>
        <div className={classes.cartDiv}>
          <ActivityTable wallet={wallet} />
        </div>
      </TabPanel>

      <TabPanel value={value} index={2} className={classes.tabPanel}>
        {isUserInWalletGettingSuccess && !orders ? (
          <Typography variant="h2" align="center" style={{ color: "white" }}>
            No Arts To Show!
          </Typography>
        ) : (
          <div className={classes.tabNft}>
            <InWalletSectionOrders />
          </div>
        )}
      </TabPanel>
    </div>
  );
}

const InWalletSection = () => {
  const {
    userInWallet: { nfts },
    isUserInWalletGetting,
  } = useSelector((state) => state.userProfile);

  const { walletAddress } = useSelector((state) => state.auth.user);

  const { wallet } = useParams();

  return isUserInWalletGetting
    ? Array(4)
        .fill(1)
        ?.map((item, i) => <NftCardSkelton key={i} />)
    : nfts.map((item) => {
        return (
          <Card item={item} isUser={walletAddress === wallet} isCollection />
        );
      });
};

const InWalletSectionOrders = () => {
  const {
    userInWallet: { orders },
    isUserInWalletGetting,
  } = useSelector((state) => state.userProfile);

  const { walletAddress } = useSelector((state) => state.auth.user);

  const { wallet } = useParams();

  return isUserInWalletGetting
    ? Array(4)
        .fill(1)
        ?.map((item, i) => <NftCardSkelton key={i} />)
    : orders.map((item) => {
        return <Card item={item} isUser={walletAddress === wallet} isOrders />;
      });
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.primary,
    padding: "28px",
  },

  tabsContainer: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,

    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },

    "& .MuiTab-textColorInherit.Mui-selected": {
      fontSize: "2rem",
      minWidth: "30%",
    },

    "& .MuiTab-textColorInherit": {
      fontSize: "2rem",
      minWidth: "30%",
    },
  },
  tabPanel: {
    padding: "28px 0",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: theme.palette.text.primary,
    marginBottom: "30px",
    "& p": {
      fontSize: "25px",
    },
    "& svg": {
      fontSize: "25px",
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  cartDiv: {
    padding: "0 100px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  tabNft: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(290px, 1fr))",
    justifyContent: "space-around",
    columnGap: "20px",
    rowGap: "100px",

    [theme.breakpoints.down("xs")]: {
      placeItems: "flex-end",

      rowGap: "80px",
    },

    [theme.breakpoints.down("xs")]: {
      placeItems: "center",
      gridTemplateColumns: "1fr",
      rowGap: "80px",
    },
  },
}));
