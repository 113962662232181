import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    position: "relative",
    marginBottom: 10,

    "& * ": {
      fontFamily: "Poppins, sans-serif",
      color: "white",
    },
    "& .MuiFormLabel-root": {
      fontSize: 16,
      fontWeight: "bold",
      color: "white",
    },

    "& .MuiFormHelperText-root ": {
      position: "absolute",
      bottom: -23,
      fontSize: 13,
      color: "white",
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },

    "&  .MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
}));
