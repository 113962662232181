import {
  Box,
  DialogContent,
  IconButton,
  Input,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import CancelIcon from "@material-ui/icons/Cancel";

import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useStyles } from "./style";
import Dialog from "../../Dialog";
import CardPopUp from "../../popupCard";
import TransactionModal from "../../modals/transactionModal";
import ApprovalModal from "../../modals/approvalModal";
import CustomButton from "../../buttons";
import {
  cancelListing,
  createOrder,
  updateOrder,
} from "../../../redux/listing/listing.actions";
import { useContractFunctions } from "../../../redux/contract/utils";
import { getNetworkInfo } from "../../../redux/wallet/wallet.actions";
import { useIsUserAuthenticated } from "../../../utils/utils";
export default function ListingModal({ showModal, setShowModal, details }) {
  const classes = useStyles();

  const handleClose = () => {
    setShowModal({
      ...showModal,
      listingModal: false,
    });
  };
  return (
    <Dialog open={showModal.listingModal} className={classes.loginMain}>
      <ModalContent handleClose={handleClose} details={details} />
    </Dialog>
  );
}

const ModalContent = ({ handleClose, details }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tokenId, artPrice, orderId } = details;
  const navigate = useNavigate();

  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });
  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
  };

  const [txInfoApproval, setTxInfoApproval] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });

  const onTxApprovalModalClose = () => {
    setTxInfoApproval({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
  };

  const onTxApprovalModalExited = () => {
    // if success
    // redirect
    // if  failure rest state
  };
  const { isMarketplaceApproved } = useContractFunctions({ setTxInfoApproval });

  const onTxModalExited = () => {
    handleClose();
    navigate("/nft-detail/" + tokenId);
  };
  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <TransactionModal
          txInfo={txInfo}
          onClose={onTxModalClose}
          onExited={onTxModalExited}
        />
        <ApprovalModal
          txInfo={txInfoApproval}
          onClose={onTxApprovalModalClose}
          onExited={onTxApprovalModalExited}
        />

        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleClose}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <Box className={classes.loginGrid}>
          <Box className={classes.imageContainer}>
            <Typography className="typoo" variant="h2">
              Listing
            </Typography>
          </Box>

          <Box className={classes.endContainer}>
            <Box>
              <CardPopUp details={details} />
            </Box>
            {Boolean(Number(artPrice)) ? (
              <UpdateCancelListing orderId={orderId} setTxInfo={setTxInfo} />
            ) : (
              <PutOnListing
                isMarketplaceApproved={isMarketplaceApproved}
                setTxInfoApproval={setTxInfoApproval}
                tokenId={tokenId}
                setTxInfo={setTxInfo}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};
const UpdateCancelListing = ({ setTxInfo, orderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isCorrectNetwork } = useSelector((state) => state.walletSlicer);

  const validationSchema = yup.object({
    price: yup.number("").moreThan(0, "Price must be greater than 0."),

    isUpdateOrder: yup.boolean(""),
  });
  const formik = useFormik({
    initialValues: {
      price: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!isCorrectNetwork) {
        dispatch(getNetworkInfo());
        return;
      }
      dispatch(updateOrder({ ...values, orderId, setTxInfo }));
    },
  });
  const handleCancelListing = () => {
    if (!isCorrectNetwork) {
      dispatch(getNetworkInfo());
      return;
    }
    dispatch(cancelListing({ orderId, setTxInfo }));
  };
  return (
    <form className={classes.secondSection}>
      <Typography Variant="h1" className="typoo1">
        update Price
      </Typography>
      <Box className={classes.priceBox}>
        <Input
          placeholder="update price"
          className={classes.price}
          disableUnderline
          type="number"
          name="price"
          onChange={formik.handleChange}
        />
        <Box style={{ display: "flex", gap: 5 }}>
          <AllInclusiveIcon className={classes.iconn} />
          <Typography variant="body1" style={{ paddingTop: 3 }}>
            {" "}
            matic
          </Typography>
        </Box>
      </Box>

      <Box className={classes.cancelandupdatesec}>
        <CustomButton
          variant="outlined"
          className="button"
          onClick={formik.submitForm}
        >
          update
        </CustomButton>
        <CustomButton
          variant="outlined"
          className="button"
          style={{ marginLeft: "20px" }}
          onClick={handleCancelListing}
        >
          cancel listing
        </CustomButton>
      </Box>
    </form>
  );
};

const PutOnListing = ({
  isMarketplaceApproved,
  setTxInfoApproval,
  tokenId,
  setTxInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUserAuthenticated = useIsUserAuthenticated();
  const initialValues = {
    price: "",

    agree: false,
  };
  const nftSchema = yup.object({
    agree: yup.boolean().oneOf([true], "you should agree to terms and service"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: nftSchema,
    onSubmit: async (values) => {
      const Auth = isUserAuthenticated();
      const status =
        Auth && (await isMarketplaceApproved({ setTxInfoApproval }));
      if (status && Auth)
        dispatch(createOrder({ ...values, tokenId, setTxInfo }));
    },
  });
  return (
    <form className={classes.secondSection}>
      <Typography Variant="h1" className="typoo1">
        Price
      </Typography>
      <Box className={classes.priceBox}>
        <Input
          placeholder="price"
          className={classes.price}
          disableUnderline
          type="number"
          onChange={formik.handleChange}
          name="price"
        />
        <Box style={{ display: "flex", gap: 5 }}>
          <AllInclusiveIcon className={classes.iconn} />
          <Typography variant="body1" style={{ paddingTop: 3 }}>
            {" "}
            matic
          </Typography>
        </Box>
      </Box>
      <div className={classes.checkSec}>
        <Checkbox
          className="checkbox"
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          name="agree"
          onChange={formik.handleChange}
        />
        <Box
          className={
            formik.touched.agree && Boolean(formik.errors.agree)
              ? classes.errormessage
              : classes.checktext
          }
        >
          <Typography variant="body1">
            {" "}
            i agree to list this NFT to the{" "}
          </Typography>
          <Typography variant="body1" className="text">
            marketplace
          </Typography>
        </Box>
      </div>
      <Box className={classes.buttonSec}>
        <CustomButton
          variant="outlined"
          className="button"
          onClick={formik.submitForm}
        >
          {" "}
          List
        </CustomButton>
      </Box>
    </form>
  );
};
