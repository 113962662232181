import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducers";
import { fillOrder } from "./trade.actions";

const initialState = {
  isOrderFilled: false,
  isOrderFillLoading: false,
  isOrderFillFailed: false,
  orderFillData: {},
};

const Trade = createSlice({
  name: "Trade",
  initialState,
  reducers,
  extraReducers: {
    [fillOrder.pending]: (state, action) => {
      state.isOrderFillLoading = true;
    },
    [fillOrder.fulfilled]: (state, action) => {
      state.isOrderFillLoading = false;
      state.isOrderFilled = true;
      state.orderFillData = action.payload;
    },
    [fillOrder.rejected]: (state, action) => {
      state.isOrderFillLoading = false;
      state.isOrderFillFailed = true;
    },
  },
});

export default Trade.reducer;
export const { resetTradeState } = Trade.actions;
