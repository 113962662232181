import { createSlice } from "@reduxjs/toolkit";
import { getFeatureArts } from "./featuresNfts.actions";
const initialState = {
  isgetFeatureArtsGetting: false,
  isgetFeatureArtsGettingSuccess: false,
  isgetFeatureArtsGettingFailed: false,
  featureArts: [],
};

const getFeatureArtsSlicer = createSlice({
  name: "getFeatureArts",
  initialState,
  extraReducers: {
    [getFeatureArts.pending]: (state) => {
      state.isgetFeatureArtsGetting = true;
      state.isgetFeatureArtsGettingSuccess = false;
      state.isgetFeatureArtsGettingFailed = false;
    },
    [getFeatureArts.fulfilled]: (state, action) => {
      state.isgetFeatureArtsGetting = false;
      state.isgetFeatureArtsGettingSuccess = true;
      state.isgetFeatureArtsGettingFailed = false;
      state.featureArts = action.payload.nfts;
    },
    [getFeatureArts.rejected]: (state) => {
      state.isgetFeatureArtsGetting = false;
      state.isgetFeatureArtsGettingSuccess = false;
      state.isgetFeatureArtsGettingFailed = true;
    },
  },
});

export default getFeatureArtsSlicer.reducer;
