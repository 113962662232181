import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  imageRend: {
    maxWidth: "455.35px",

    minWidth: "455.35px",
    minHeight: "400px",
    maxHeight: "400px",

    height: "100%",
    borderRadius: "10px",
    objectFit: "cover",
  },

  relatedImagCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    minHeight: "400px",
    marginRight: "10px",
  },

  relImage: {
    width: "100%",
    maxHeight: "70px",
    minHeight: "70px",
    objectFit: "cover",

    cursor: "pointer",
  },

  relImageDiv: {
    minWidth: "100%",
    maxHeight: "100px",
    minHeight: "100px",
    display: "flex",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "Center",
    border: "3px solid white",
    color: "white",
  },
  btn: {
    width: "max-content",
    marginTop: "20px",
  },
  mainImageRenderer: {
    display: "flex",

    alignItems: "center",
  },
}));
