import { useSelector } from "react-redux";
import { chainIdToNetworkInfo } from "../web3/constants";

import { TX_APPROVAL_STATUS } from "../../components/modals/approvalModal/constants";
import { getPolygonTxFees } from "../../utils/utils";

export const useContractFunctions = () => {
  const { erc721Instance } = useSelector((state) => state.Contracts);
  const { connectedWallet, connectedChainId } = useSelector(
    (state) => state.walletSlicer
  );

  const isMarketplaceApproved = async (payload) => {
    const { setTxInfoApproval } = payload;
    try {
      const marketPlaceAddress =
        chainIdToNetworkInfo[connectedChainId].contracts.marketplaceAddress;
      const isApprovedForAll = await erc721Instance.methods
        .isApprovedForAll(connectedWallet, marketPlaceAddress)
        .call();
      if (isApprovedForAll) return isApprovedForAll;
      setTxInfoApproval({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Please accept tx for approval from wallet",
      });
      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const gasLimit = await erc721Instance.methods
            .setApprovalForAll(marketPlaceAddress, true)
            .estimateGas({ from: connectedWallet });

          erc721Instance.methods
            .setApprovalForAll(marketPlaceAddress, true)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })

            .once("transactionHash", function (txHash) {
              setTxInfoApproval({
                txStatus: TX_APPROVAL_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
              // this block will be executed once the transaction has been accept from wallet by the user
            })
            .once("receipt", async () => {
              setTxInfoApproval({
                txStatus: TX_APPROVAL_STATUS.SUCCESS,
                txMessage: "You have been approved",
                txHash: null,
              });
              resolve(true);
            })
            .on("error", reject);
        } catch (err) {
          reject(err);
        }
      });
    } catch (err) {
      setTxInfoApproval({
        txStatus: TX_APPROVAL_STATUS.REJECTED,
        txMessage: err.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      return false;
    }
  };
  const isAuctionContractApproved = async (payload) => {
    const { setTxInfoApproval } = payload;
    try {
      const auctionAddress =
        chainIdToNetworkInfo[connectedChainId].contracts.auctionAddress;
      const isApprovedForAll = await erc721Instance.methods
        .isApprovedForAll(connectedWallet, auctionAddress)
        .call();
      if (isApprovedForAll) return isApprovedForAll;
      setTxInfoApproval({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Please accept tx for approval from wallet",
      });
      return await new Promise((resolve, reject) => {
        erc721Instance.methods
          .setApprovalForAll(auctionAddress, true)
          .send({ from: connectedWallet })

          .once("transactionHash", function (txHash) {
            setTxInfoApproval({
              txStatus: TX_APPROVAL_STATUS.PENDING,
              txMessage: null,
              txHash,
            });
            // this block will be executed once the transaction has been accept from wallet by the user
          })
          .once("receipt", async (receipt) => {
            setTxInfoApproval({
              txStatus: TX_APPROVAL_STATUS.SUCCESS,
              txMessage: "You have been approved",
              txHash: null,
            });
            resolve(true);
          })
          .on("error", reject);
      });
    } catch (err) {
      setTxInfoApproval({
        txStatus: TX_APPROVAL_STATUS.REJECTED,
        txMessage: err.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      return false;
    }
  };

  return { isMarketplaceApproved, isAuctionContractApproved };
};
