import { OwnRareURL } from "./config";


export const requestSignUp = async(payload) => {
    try {
        const res = await OwnRareURL.post("/users/singUp", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const requestAllArts = async({
    search,
    pageno,
}) => {
    try {
        const res = await OwnRareURL.get("/nfts/getArts", search ? {
            params: {
                search,
                pageno,
            },
        } : {
            params: {
                pageno,
            },
        });

        return res;
    } catch (error) {
        throw error;
    }
};

export const getSingleNftData = async(payload) => {
    try {
        const res = await OwnRareURL.get("/nfts/getSingleArt", {
            params: {
                tokenId: payload,
            },
        });

        return res;
    } catch (error) {
        throw error;
    }
};

export const requestGetFeaturedArts = async(payload) => {
    try {
        const res = await OwnRareURL.get("/nfts/readFeaturedArts");

        return res;
    } catch (error) {
        throw error;
    }
};

export const requestGetUserProfile = async(payload) => {
    try {
        const res = await OwnRareURL.get("/users/getUserProfile", {
            params: {
                wallet: payload,
            },
        });

        return res;
    } catch (error) {
        throw error;
    }
};

export const updateProfileAPI = async(payload) => {
    const res = await OwnRareURL.post("/users/updateProfileInfo", payload);
    return res;
};

export const userCoverImageUpadte = async(payload) => {
    try {
        const res = await OwnRareURL.post("/users/updateCoverPic", payload);

        return res;
    } catch (error) {
        throw error;
    }
};

export const updateProfilePicAPI = async(payload) => {
    try {
        const res = await OwnRareURL.post("/users/updateProfilePic", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const getUserInWalletData = async(payload) => {
    const res = await OwnRareURL.get("/users/inWallet", {
        params: {
            wallet: payload,
        },

    });
    return res;
};

export const headerSearch = async(payload) => {
    try {
        const res = await OwnRareURL.get("/search/searchAll", {
            params: {
                search: payload,
            },
        });
        return res;
    } catch (error) {
        throw error;
    }
};

export const mintNft = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/mint", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const putOnSale = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/putOnSaleFixedPrice", payload);
        return res;
    } catch (error) {
        throw error;
    }
};
export const updateListingPrice = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/updateOrderPrice", payload);
        return res;
    } catch (error) {
        throw error;
    }
};
export const cancelListingPrice = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/cancelOrder", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const buyNft = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/directTransfer", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const requestNft = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/requestNFT", payload);
        return res;
    } catch (error) {
        throw error;
    }
};
export const updateDeliveryId = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/updateOrderDeliveryId", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const burnNftRequest = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/burnNft", payload);
        return res;
    } catch (error) {
        throw error;
    }
};
export const artHistoryApi = async(payload) => {
    try {
        const res = await OwnRareURL.get("/nfts/history", {
            params: {
                tokenId: payload,
            },
        });
        return res;
    } catch (error) {
        throw error;
    }
};
export const ArtistActivityHistoryApi = async(payload) => {
    try {
        const res = await OwnRareURL.get("/users/userHistory", {
            params: {
                wallet: payload,
            },
        });
        return res;
    } catch (error) {
        throw error;
    }
};
export const burnListing = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/burnMintedNft", payload);
        return res;
    } catch (error) {
        throw error;
    }
};

export const revertOrderApi = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/nftRevertToOwner", payload);
        return res;
    } catch (error) {
        throw error;
    }
};
export const cancelNftRequest = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/cancelNftRequest", payload);
        return res;
    } catch (error) {
        throw error;
    }
};
export const uploadImagesApi = async(payload) => {
    try {
        const res = await OwnRareURL.post("/nfts/uploadImages", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return res;
    } catch (error) {
        throw error;
    }
};