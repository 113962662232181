import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { requestAllArts } from "../../Http/api";
export const getAllArts = createAsyncThunk(
  "arts/getAllArts",
  async (payload) => {
    try {
      const { data } = await requestAllArts(payload);

      return data;
    } catch (error) {
      throw error;
    }
  }
);
