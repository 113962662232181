import {
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { artHistory } from "../../redux/singleNftDetails/singleNftDetails.actions";
import LaunchIcon from "@material-ui/icons/Launch";
const CusTable = ({ tokenId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { artHistory: history, isArtHistoryLoading } = useSelector(
    (state) => state.singleNftDetails
  );

  useEffect(() => {
    dispatch(artHistory(tokenId));
  }, [tokenId, dispatch]);
  return (
    <div>
      {isArtHistoryLoading ? (
        <CircularProgress />
      ) : history.length === 0 ? (
        <>NO ACTIVITY</>
      ) : (
        <TableContainer className={classes.TableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.cell}>Transaction</TableCell>
                <TableCell className={classes.cell} align="center">
                  By
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Address
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Price
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  TxHash
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map(
                (
                  {
                    transactionType,
                    username,
                    userWallet,
                    transactionAmount,
                    date,
                    transactionHash,
                  },
                  i
                ) => {
                  const dateString = new Date(date);
                  return (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {transactionType}
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        {username}
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        {userWallet.slice(0, 10) + "..."}
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        {transactionAmount}
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        {dateString.toLocaleDateString()}
                      </TableCell>
                      <TableCell className={classes.cell} align="center">
                        <a
                          href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LaunchIcon className={classes.icon} />
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CusTable;
const useStyles = makeStyles((theme) => ({
  TableContainer: {
    width: "100%",
    background: theme.palette.background.primary,
    border: "1px solid white",
  },
  cell: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));
