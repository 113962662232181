import { makeStyles } from "@material-ui/core";
import React from "react";
import AboutSection from "../../components/about";

export default function Aboutus() {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <AboutSection />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "40px 0px",
    backgroundColor: theme.palette.background.primary,
  },
}));
