import { makeStyles } from "@material-ui/styles";
import back from "../../assets/profileBanner.png";
import profile from "../../assets/images/profileAvatar.webp";
import { Typography } from "@material-ui/core";
import CusTabs from "../../components/tabs";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInWallet,
  userProfile,
} from "../../redux/userProfile/userProfile.actions";
import { Skeleton } from "@material-ui/lab";
import { basecoverUrl, baseProfileUrl } from "../../Http/config";
import CustomButton from "../../components/buttons";

const UserProfile = () => {
  const classes = useStyles();

  const { wallet } = useParams();
  const dispatch = useDispatch();
  const {
    isuserProfileGetting,
    user: { userName, image, cover, bio },
  } = useSelector((state) => state.userProfile);
  const { walletAddress } = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(userProfile(wallet));
    dispatch(getUserInWallet(wallet));
  }, [wallet, dispatch]);

  if (isuserProfileGetting) return <UserProfileSkelton />;
  return (
    <div className={classes.contianer}>
      <div className={classes.profileContainer}>
        <img
          src={cover ? basecoverUrl + cover : back}
          alt="cover"
          className={classes.cover}
        />
        <div>
          <img
            className={classes.img}
            src={image ? baseProfileUrl + image : profile}
            alt="profile"
          />
        </div>
      </div>
      <div className={classes.profileInfo}>
        <Typography className={classes.title}>
          @{userName || "Ownrare"}
        </Typography>
        <Typography className={classes.address}>
          {wallet || "no wallet address"}
        </Typography>
        <Typography className={classes.typo}>
          {bio || "Nothing to show"}
        </Typography>
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {walletAddress === wallet && (
            <Link to={`/editprofile/${wallet}`} className={classes.links}>
              <CustomButton variant="contained"> edit profile</CustomButton>
            </Link>
          )}
        </div>
      </div>

      {walletAddress === wallet && (
        <div>
          <CusTabs />
        </div>
      )}
    </div>
  );
};

export default UserProfile;

const UserProfileSkelton = () => {
  const classes = useStyles();
  return (
    <div className={classes.contianer}>
      <div className={classes.profileContainer}>
        <Skeleton
          animation="wave"
          className={classes.cover}
          height="400px"
          variant="rect"
        />
        <div>
          <Skeleton
            className={classes.img}
            animation="wave"
            width="200px"
            height="200px"
            variant="circle"
          />
        </div>
      </div>
      <div className={classes.profileInfoSkel}>
        <Skeleton className={classes.title} animation="wave" width="300px" />
        <Skeleton className={classes.address} animation="wave" width="200px" />
        <Skeleton className={classes.typo} animation="wave" width="200px" />
      </div>
      <div>
        <CusTabs />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  cover: {
    width: "100%",
    minHeight: "50vh",
    maxHeight: "50vh",
    objectFit: "cover",
  },
  contianer: {
    backgroundColor: theme.palette.background.primary,
  },

  img: {
    borderRadius: "50%",
    position: "absolute",
    right: "50%",
    left: "50%",
    width: "200px",
    height: "200px",
    transform: "translate(-50%, -50%)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  profileInfo: {
    padding: "120px 0 100px 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "180px 28px",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "36px",
    fontWeight: 800,
    fontStyle: "italic",
  },
  address: {
    color: theme.palette.text.primary,
    fontSize: "22px",
    marginTop: 18,
    wordWrap: "break-word",
  },
  typo: {
    color: theme.palette.text.primary,
    fontSize: "17px",
    marginTop: 15,
  },

  profileInfoSkel: {
    display: "flex",
    padding: "120px 0 100px 0",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));
