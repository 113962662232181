import { ClickAwayListener, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/icons/logo.svg";
import CustomButton from "../buttons";
import SearchBar from "../searchBar";
import Drawer from "../drawer";
import { useDispatch, useSelector } from "react-redux";
import { toggleshowBurnNft } from "../../redux/login/login.slicer";
import { connectWallet } from "../../redux/wallet/wallet.actions";
import UserWalletConnection from "./userWalletConnection";
import SvgIcon from "../icons";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";
import SearchDropDown from "../SearchDropDown";
import { getSearchResults } from "../../redux/headerSearch/headerSearch.actions";

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    setText(e.target.value);
    setOpen(true);
    setTimeout(() => {
      dispatch(getSearchResults(e.target.value));
    }, 700);
  };

  return (
    <div className={classes.navbar}>
      <Link to="/">
        <img src={Logo} alt="logo" />
      </Link>
      <div style={{ maxWidth: "356px" }}>
        <SearchBar onChange={handleSearchChange} />
        {open && Boolean(text) && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
          >
            <div>
              <SearchDropDown close={handleClickAway} />
            </div>
          </ClickAwayListener>
        )}
      </div>

      <div className={classes.navLinks}>
        <Link className={classes.links} to="/market">
          Market
        </Link>
        <Link className={classes.links} to="/faqs">
          FAQs
        </Link>
        <Link className={classes.links} to="/aboutus">
          About Us
        </Link>
      </div>
      <div className={classes.userBox}>
        <UserWalletConnection />

        {/* <div className={classes.userIconBox}>
          <SvgIcon circle className={classes.circleIcon} />
          <PersonOutlineRoundedIcon className={classes.userIcon} />
        </div> */}
      </div>
      <div className={classes.drawer}>
        <Drawer />
      </div>
    </div>
  );
};

export default Navbar;
const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: theme.palette.background.primary,
    height: "90px",
    display: "flex",
    alignItems: "center",
    padding: "0 59px",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 28px",
    },
  },
  navLinks: {
    display: "flex",
    justifyContent: "space-between",
    gap: "50px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  links: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontWeight: "600",
    width: "75px",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  userIconBox: {
    position: "relative",
  },
  circleIcon: {
    width: "52px",
  },
  userIcon: {
    position: "absolute",
    left: 1,
    fontSize: "50px",
    color: theme.palette.text.primary,
  },
  drawer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
