import * as yup from "yup";

export const nftSchema = yup.object({
  image1: yup

    .mixed("")
    .test(
      "image",
      "Please select a valid image or video.",
      (file) =>
        file &&
        /\.(jpg|jpeg|png|gif|tiff|tif|heif|heic|svg|svgz|ai|mp4|ogg|webm|mov)$/.test(
          file.name.toLowerCase()
        )
    )
    .required("Image is required"),
  image2: yup

    .mixed("")
    .test(
      "image",
      "Please select a valid image or video.",
      (file) =>
        file &&
        /\.(jpg|jpeg|png|gif|tiff|tif|heif|heic|svg|svgz|ai|mp4|ogg|webm|mov)$/.test(
          file.name.toLowerCase()
        )
    )
    .required("Image is required"),
  image3: yup

    .mixed("")
    .test(
      "image",
      "Please select a valid image or video.",
      (file) =>
        file &&
        /\.(jpg|jpeg|png|gif|tiff|tif|heif|heic|svg|svgz|ai|mp4|ogg|webm|mov)$/.test(
          file.name.toLowerCase()
        )
    )
    .required("Image is required"),
  image4: yup

    .mixed("")
    .test(
      "image",
      "Please select a valid image or video.",
      (file) =>
        file &&
        /\.(jpg|jpeg|png|gif|tiff|tif|heif|heic|svg|svgz|ai|mp4|ogg|webm|mov)$/.test(
          file.name.toLowerCase()
        )
    )
    .required("Image is required"),
});
