import {
  UseCheckForAdminRole,
  useCheckWalletConnection,
  useInitializeContracts,
  useInitializeWeb3Packages,
  useOnProviderChange,
} from "./hooks/web3.hooks";

const UniversalHooks = () => {
  useInitializeWeb3Packages();
  useOnProviderChange();
  useCheckWalletConnection();
  useInitializeContracts();
  UseCheckForAdminRole();

  return null;
};

export default UniversalHooks;
