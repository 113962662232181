import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.secondary.main,
  },
  checkedIcon: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "4px",
    borderColor: theme.palette.secondary.main,

    // "input:hover ~ &": {
    //   borderColor: theme.palette.primary.secondary,
    // },
  },
}));

export default function CheckBox(props) {
  const classes = useStyles();
  return (
    <FormControlLabel
      value={props.value}
      style={{
        justifyContent: "space-between",
        flexDirection: "row-reverse",
      }}
      control={
        <Checkbox
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          {...props}
        />
      }
      label={props.label}
    />
  );
}
