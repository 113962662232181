import {
  Box,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Dialog from "../Dialog";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { togglenftDeliver } from "../../redux/login/login.slicer";
import CardPopUp from "../popupCard";

export default function NftDelivery({ showModal, setShowModal, details }) {
  const classes = useStyles();

  const handleClose = () => {
    setShowModal({
      ...showModal,
      viewNftModal: false,
    });
  };

  return (
    <Dialog open={showModal.viewNftModal} className={classes.loginMain}>
      <ModalContent handleClose={handleClose} details={details} />
    </Dialog>
  );
}

const ModalContent = ({ handleClose, details }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleClose}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <Box className={classes.loginGrid}>
          <Box className={classes.imageContainer}>
            <Typography className="typoo" variant="h2">
              delivery
            </Typography>
            <Typography className="typoo1" variant="h2">
              details
            </Typography>
          </Box>

          <Box className={classes.endContainer}>
            <Box>
              <CardPopUp details={details} />
            </Box>
            <Box className={classes.secondSection}>
              <Typography variant="h1" className="typoo1">
                {details.ownerName}
              </Typography>

              <Box className={classes.innersection}>
                <Typography className="text1" variant="body1">
                  Address:
                </Typography>{" "}
                <Typography className="text2" variant="body1">
                  {details.deliveryAddress.slice}
                </Typography>
              </Box>
              <Box className={classes.innersection}>
                <Typography className="text1" variant="body1">
                  Postal Code:
                </Typography>{" "}
                <Typography className="text2" variant="body1">
                  {details.postalCode}
                </Typography>
              </Box>
              <Box className={classes.innersection}>
                <Typography className="text1" variant="body1">
                  Nationality:
                </Typography>{" "}
                <Typography className="text2" variant="body1">
                  {details.country}
                </Typography>
              </Box>
              <Box className={classes.innersection}>
                <Typography className="text1" variant="body1">
                  City:
                </Typography>{" "}
                <Typography className="text2" variant="body1">
                  {details.city}
                </Typography>
              </Box>
              <Box className={classes.innersection}>
                <Typography className="text1" variant="body1">
                  Contact Number:
                </Typography>{" "}
                <Typography className="text2" variant="body1">
                  {details.contactNum}
                </Typography>
              </Box>
              {/* <Box className={classes.innersection}>
                <Typography className="text1" variant="body1">
                  Email:
                </Typography>{" "}
                <Typography className="text2" variant="body1">
                  asad@gmail.com
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },
  loginMain: {
    "& .MuiPaper-root": {
      marginTop: 80,
      width: "100%",

      maxWidth: "60%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100% - 100px)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },

      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },

  imageContainer: {
    textTransform: "capitalize",
    display: "flex",
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 60,

    " & .typoo1": {
      color: theme.palette.background.blue,
    },
  },

  innerText: {
    paddingTop: 10,
    display: "flex",
    maxWidth: 450,
    margin: "auto",
    justifyItems: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .typoo": {
      fontWeight: 300,
      fontSize: 18,
      textAlign: "center",
      // color: theme.palette.text.primary,
    },
  },

  endContainer: {
    padding: "20px 40px",
    marginTop: 20,
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    placeContent: "center",
    justifyContent: "center",
    gap: 15,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr ",
    },

    "& .innerCont": {
      width: 300,
      height: 60,

      borderRadius: 50,
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },

  secondSection: {
    paddingTop: 60,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },

    "& .typoo1": {
      textTransform: "upperCase",
      fontSize: 27,
      color: theme.palette.background.blue,
      width: "15ch",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  innersection: {
    display: "flex",
    gap: 10,
    padding: 5,
    textTransform: "capatilaze",

    "& .text1": {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.text.primary,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
    "& .text2": {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.background.blue,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
}));
