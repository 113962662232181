import { Divider as MUIDivider } from "@material-ui/core";

import { withStyles } from "@material-ui/core";

export const Divider = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0.1, 0),
    height: 1,

    background:
      " linear-gradient(270deg, rgba(0, 45, 66, 0) 11.91%, #005075 49.43%, rgba(0, 45, 66, 0) 84.63%),linear-gradient(0deg, #002D42, #002D42);",
  },
}))(MUIDivider);

export default Divider;
