import MediaRender from "../mediaRender";
import { useStyles } from "./style";

import { useState } from "react";
import CustomButton from "../buttons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrlRelImages } from "../../Http/config";

const ImageRender = ({ url, artImg, relImage = null, ownerWallet }) => {
  const [selectedImage, setSelectedImage] = useState(url);
  const { walletAddress } = useSelector((state) => state.auth.user);
  const { isAdmin } = useSelector((state) => state.walletSlicer);
  const classes = useStyles();
  const naviagte = useNavigate();
  const handleNavigateToUploadImages = () => {
    naviagte("/upload-product-images");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className={classes.mainImageRenderer}>
        {relImage && (
          <div className={classes.relatedImagCont}>
            <img
              src={url}
              className={classes.relImage}
              alt="irelimage"
              onClick={(event) => {
                setSelectedImage(event.target.src);
              }}
            />
            {relImage.map((item, index) => {
              return (
                <img
                  src={baseUrlRelImages + item}
                  className={classes.relImage}
                  alt="irelimage"
                  onClick={(event) => {
                    setSelectedImage(event.target.src);
                  }}
                />
              );
            })}
          </div>
        )}
        <MediaRender
          url={selectedImage}
          artImg={artImg}
          nftImage={classes.imageRend}
        />
      </div>
      {Boolean(Number(isAdmin)) && ownerWallet === walletAddress && (
        <CustomButton
          variant="contained"
          className={classes.btn}
          onClick={handleNavigateToUploadImages}
        >
          Upload Product Images
        </CustomButton>
      )}
    </div>
  );
};

export default ImageRender;
