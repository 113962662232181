import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TX_APPROVAL_STATUS } from "../../components/modals/approvalModal/constants";
import { TX_STATUS } from "../../components/modals/transactionModal/constants";
import { burnNftRequest, burnListing } from "../../Http/api";
import { getPolygonTxFees } from "../../utils/utils";

export const burnNft = createAsyncThunk(
  "burn/burnNft",
  async (payload, { dispatch, getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;

    const { orderDeliveryId, setTxInfo } = payload;

    try {
      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Please accept tx for approval from wallet",
      });

      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );

          const gasLimit = await marketplaceInstance.methods
            .completeDeliveryOrder(orderDeliveryId)
            .estimateGas({ from: connectedWallet });
          marketplaceInstance.methods
            .completeDeliveryOrder(orderDeliveryId)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })
            .once("transactionHash", function (txHash) {
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;
              const { tokenId, deliveryOrderId } =
                receipt.events.DeliveryOrder.returnValues;
              try {
                const fd = new FormData();
                fd.append("orderDeliveryId", deliveryOrderId);
                fd.append("tokenId", tokenId);

                const { apiResp } = await burnNftRequest(fd);

                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt });
                return apiResp;
              } catch (error) {
                reject(error);
              }
            })
            .on("error", reject);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      throw error;
    }
  }
);

export const burnBeforeListing = createAsyncThunk(
  "burn/burnNft",
  async (payload, { dispatch, getState }) => {
    const { erc721Instance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;

    const { tokenId, setTxInfo } = payload;

    try {
      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Please accept tx for approval from wallet",
      });

      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );

          const gasLimit = await erc721Instance.methods
            .burn(tokenId)
            .estimateGas({ from: connectedWallet });
          erc721Instance.methods
            .burn(tokenId)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })
            .once("transactionHash", function (txHash) {
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;

              try {
                const fd = new FormData();

                fd.append("tokenId", tokenId);

                const { apiResp } = await burnListing(fd);

                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt });
                return apiResp;
              } catch (error) {
                reject(error);
              }
            })
            .on("error", reject);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      throw error;
    }
  }
);
