import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.background.primary,
    color: theme.palette.text.main,
    border: "2px solid " + theme.palette.secondary.main,
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "max-content",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "0.9rem",
      padding: "7px",
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
      },
      borderRadius: "30px",
    },
  },
}));

export default function SearchInput(props) {
  const classes = useStyles();

  return <OutlinedInput {...props} className={classes.input} />;
}
