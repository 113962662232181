import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  requestGetUserProfile,
  getUserInWalletData,
  ArtistActivityHistoryApi,
} from "../../Http/api";
export const userProfile = createAsyncThunk(
  "user/getUserProfile",
  async (payload) => {
    try {
      const { data } = await requestGetUserProfile(payload);

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getUserInWallet = createAsyncThunk(
  "user/getInWallet",

  async (payload) => {
    try {
      const { data } = await getUserInWalletData(payload);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const artistActivityHistory = createAsyncThunk(
  "userProfile/artistActivityHistory",
  async (payload) => {
    try {
      const { data } = await ArtistActivityHistoryApi(payload);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
