import { createSlice } from "@reduxjs/toolkit";
import {
  userProfile,
  getUserInWallet,
  artistActivityHistory,
} from "./userProfile.actions";
const initialState = {
  isuserProfileGetting: false,
  isuserProfileGettingSuccess: false,
  isuserProfileGettingFailed: false,
  user: [],

  isUserInWalletGetting: false,
  isUserInWalletGettingSuccess: false,
  isUserInWalletGettingFailed: false,
  userInWallet: { nfts: [], orders: [] },

  isActivityLoading: false,
  isActivityLoadingSuccess: false,
  isActivityLoadingFailed: false,
  activityData: [],
};

const userProfileSlicer = createSlice({
  name: "userProfile",
  initialState,
  extraReducers: {
    [userProfile.pending]: (state) => {
      state.isuserProfileGetting = true;
      state.isuserProfileGettingSuccess = false;
      state.isuserProfileGettingFailed = false;
    },
    [userProfile.fulfilled]: (state, action) => {
      state.isuserProfileGetting = false;
      state.isuserProfileGettingSuccess = true;
      state.isuserProfileGettingFailed = false;
      state.user = action.payload;
    },
    [userProfile.rejected]: (state) => {
      state.isuserProfileGetting = false;
      state.isuserProfileGettingSuccess = false;
      state.isuserProfileGettingFailed = true;
    },

    [getUserInWallet.pending]: (state) => {
      state.isUserInWalletGetting = true;
      state.isUserInWalletGettingSuccess = false;
      state.isUserInWalletGettingFailed = false;
    },
    [getUserInWallet.fulfilled]: (state, action) => {
      state.isUserInWalletGetting = false;
      state.isUserInWalletGettingSuccess = true;
      state.isUserInWalletGettingFailed = false;
      state.userInWallet = action.payload;
    },
    [getUserInWallet.rejected]: (state) => {
      state.isUserInWalletGetting = false;
      state.isUserInWalletGettingSuccess = false;
      state.isUserInWalletGettingFailed = true;
    },

    [artistActivityHistory.pending]: (state) => {
      state.isActivityLoading = true;
    },
    [artistActivityHistory.fulfilled]: (state, action) => {
      state.isActivityLoading = false;
      state.isActivityLoadingSuccess = true;
      state.activityData = action.payload.transactionHistory;
    },
    [artistActivityHistory.rejected]: (state) => {
      state.isActivityLoading = false;
      state.isActivityLoadingFailed = true;
    },
  },
});

export default userProfileSlicer.reducer;
