import { imgixClient } from "./Http/config";

export function createIPFS_URL(cid = "") {
  if (!cid) return null;
  return `https://ipfs.io/ipfs/${cid.split("//")[1]}`;
}

export function createImgixURL(url = "", width = "auto", height = "auto") {
  url = url || "";
  return imgixClient.buildURL(url, { w: width, h: height });
}

export const getIpfsUrl = (image) => {
  return createImgixURL(createIPFS_URL(image));
};

export function whatIsFileType(file) {
  if (!file) return "could not read the file extension";
  if (
    /\.(jpg|jpeg|png|gif|tiff|tif|heif|heic|svg|svgz|ai)$/.test(
      String(file).toLowerCase()
    )
  )
    return "image";

  if (/\.(mp4|ogg|webm|mov)$/.test(file.toLowerCase())) return "video";

  return "i dont know";
}

export const sortNftsByOption = (arts = [], option) => {
  const items = [...arts];

  switch (option) {
    case "Recently Listed":
      return items.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });

    case "Oldest Listings":
      return items.sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });
    case "Price: high to low":
      return items.sort((a, b) => b.artPrice - a.artPrice);

    case "Price: Low to high":
      return items.sort((a, b) => a.artPrice - b.artPrice);

    default:
      return items;
  }
};

/**
 * @dev reads the content of file asynchronously and returns a buffer
 * @param file to read asyn as a buffer
 * @returns buffer content
 */
export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export const shortenAddress = (address) =>
  address ? `${address.slice(0, 5)}...${address.slice(-5)}` : null;

export const textOverflow = (text, limit) => {
  if (text && text.length > limit) {
    text = text.slice(0, limit) + "...";
  }
  return text;
};
