import { createSlice } from "@reduxjs/toolkit";
import { getSearchResults } from "./headerSearch.actions";
const initialState = {
  isSearchResultLoading: false,
  isSearchResultLoaded: false,
  isSearchResultLoadingFailed: false,
  artWorks: [],
  creators: [],
  searchKeyword: "",
};

const headerSearch = createSlice({
  name: "getSearchResults",
  initialState,
  extraReducers: {
    [getSearchResults.pending]: (state) => {
      state.isSearchResultLoading = true;
      state.isSearchResultLoaded = false;
      state.isSearchResultLoadingFailed = false;
    },
    [getSearchResults.fulfilled]: (state, action) => {
      state.isSearchResultLoading = false;
      state.isSearchResultLoadingFailed = false;
      state.isSearchResultLoaded = true;
      state.creators = action.payload.search_artist;
      state.artWorks = action.payload.search_nfts;
    },
    [getSearchResults.rejected]: (state) => {
      state.isSearchResultLoading = false;
      state.isSearchResultLoaded = false;
      state.isSearchResultLoadingFailed = true;
    },
  },

  reducers: {
    setHeaderSearchText: (state, action) => {
      state.searchKeyword = action.payload;
    },
  },
});
export const { setHeaderSearchText } = headerSearch.actions;
export default headerSearch.reducer;
