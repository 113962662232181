import {
  Box,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Dialog from "../Dialog";

import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../buttons";

import { switchNetwork } from "../../redux/wallet/constants";
import { ACCEPTED_CHAIN_IDs } from "../../redux/web3/constants";
import CancelIcon from "@material-ui/icons/Cancel";
import { logout } from "../../redux/auth/auth.slicer";
import { resetChainInfo } from "../../redux/wallet/wallet.slicer";
export default function WrongChain() {
  const classes = useStyles();

  const { connectedChainId, isCorrectNetwork } = useSelector(
    (state) => state.walletSlicer
  );

  return (
    <Dialog
      open={connectedChainId && !isCorrectNetwork}
      className={classes.loginMain}
    >
      <ModalContent />
    </Dialog>
  );
}

const ModalContent = () => {
  const classes = useStyles();

  const { provider } = useSelector((state) => state.Web3Instance);
  const dispatch = useDispatch();

  const handleCloseWrongChain = () => {
    dispatch(resetChainInfo());
  };

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleCloseWrongChain}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <div className={classes.wrongWalletLower}>
          <Typography>
            It seems that You are connected with wrong network
          </Typography>
          <div>
            <CustomButton
              onClick={switchNetwork.bind(
                this,
                provider,
                ACCEPTED_CHAIN_IDs[0]
              )}
              children="change chain"
              variant="contained"
            />
          </div>
        </div>
      </DialogContent>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },

  wrongWalletLower: {
    paddingTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    placeItems: "center",
    rowGap: "20px",
    padding: "30px 20px",
  },
}));
