import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./auth.actions";
const initialState = {
  isUserLogginIn: false,
  isUserLogginInSuccess: false,
  isUserLogginInFailed: false,
  user: { userWallet: null },
};

const authSlicer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isUserLogginIn = false;
      state.isUserLogginInSuccess = false;
      state.isUserLogginInFailed = false;
      state.user = { userWallet: null, userExist: null };
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.isUserLogginIn = true;
      state.isUserLogginInSuccess = false;
      state.isUserLogginInFailed = false;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.isUserLogginIn = false;
      state.isUserLogginInSuccess = true;
      state.isUserLogginInFailed = false;
      state.user = action.payload;
    },
    [userLogin.rejected]: (state) => {
      state.isUserLogginIn = false;
      state.isUserLogginInSuccess = false;
      state.isUserLogginInFailed = true;
    },
  },
});

export default authSlicer.reducer;
export const { logout } = authSlicer.actions;
