import { makeStyles } from "@material-ui/styles";
import React from "react";
import NftDetails, { SignleNftSkelton } from "../../components/detailsCard";
import CusAccordion from "../../components/accordion";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CusTable from "../../components/table";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { singleNftDetails } from "../../redux/singleNftDetails/singleNftDetails.actions";

const DetailsPage = () => {
  const classes = useStyles();
  const { token } = useParams();
  const dispatch = useDispatch();

  const {
    issingleNftDetailsGetting,
    signleNft: { description },
  } = useSelector((state) => state.singleNftDetails);

  useEffect(() => {
    dispatch(singleNftDetails(token));
  }, [token, dispatch]);

  return (
    <div className={classes.container}>
      {issingleNftDetailsGetting ? <SignleNftSkelton /> : <NftDetails />}
      <div className={classes.accordionBox}>
        <div>
          <CusAccordion
            logo={<InsertDriveFileOutlinedIcon />}
            title={"  DESCRIPTION"}
            details={description || "nothing to show !"}
          />
        </div>
        <div>
          <CusAccordion
            title="ACTIVITY"
            logo={<TimelineIcon />}
            details={<CusTable tokenId={token} />}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsPage;
const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.primary,
    padding: "28px",
  },
  accordionBox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
    padding: "50px 0",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));
