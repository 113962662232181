import { createSlice } from "@reduxjs/toolkit";
import { orderNft } from "./nftOrder.actions";

const initialState = {
  isorderNftingInProgress: false,
  isorderNftingComplete: false,
  isorderNftingRejected: false,
};

const orderNftSlice = createSlice({
  name: "orderNftSlice",
  initialState,

  extraReducers: {
    [orderNft.pending]: (state, action) => {
      state.isorderNftingInProgress = true;

      state.isorderNftingComplete = false;

      state.isorderNftingRejected = false;
    },
    [orderNft.fulfilled]: (state, action) => {
      state.isorderNftingInProgress = false;
      state.isorderNftingComplete = true;
      state.isorderNftingRejected = false;
    },
    [orderNft.rejected]: (state, action) => {
      state.isorderNftingInProgress = false;
      state.isorderNftingComplete = false;
      state.isorderNftingRejected = true;
    },
  },
  reducers: {
    resetOrderState: (state) => {
      state.isorderNftingInProgress = false;
      state.isorderNftingComplete = false;
      state.isorderNftingRejected = false;
    },
  },
});
export const { resetOrderState } = orderNftSlice.actions;
export default orderNftSlice.reducer;
