import { createSlice } from "@reduxjs/toolkit";
import { mint } from "./mint.actions";
import { reducers } from "./reducers";

const initialState = {
  isMintingInProgress: false,
  isMintingComplete: false,
  isMintingRejected: false,
  tokenId: null,
};

const mintSlice = createSlice({
  name: "mintSlice",
  initialState,
  reducers,
  extraReducers: {
    [mint.pending]: (state, action) => {
      state.isMintingInProgress = true;
    },
    [mint.fulfilled]: (state, action) => {
      state.isMintingInProgress = false;
      state.isMintingComplete = true;
      state.tokenId = action.payload.apiResponse.data.tokenId;
    },
    [mint.rejected]: (state, action) => {
      state.isMintingInProgress = false;

      state.isMintingRejected = true;
    },
  },
});

export default mintSlice.reducer;
export const { resetMintingState } = mintSlice.actions;
