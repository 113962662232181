import { createSlice } from "@reduxjs/toolkit";
import { burnNft, burnBeforeListing } from "./burnNft.actions";

const initialState = {
  isburnNftingInProgress: false,
  isburnNftingComplete: false,
  isburnNftingRejected: false,

  isburnBeforeListingProgress: false,
  isburnBeforeListingComplete: false,
  isburnBeforeListingRejected: false,
};

const burnNftSlice = createSlice({
  name: "burnNftSlice",
  initialState,

  extraReducers: {
    [burnNft.pending]: (state, action) => {
      state.isburnNftingInProgress = true;
    },
    [burnNft.fulfilled]: (state, action) => {
      state.isburnNftingInProgress = false;
      state.isburnNftingComplete = true;
    },
    [burnNft.rejected]: (state, action) => {
      state.isburnNftingInProgress = false;

      state.isburnNftingRejected = true;
    },

    [burnBeforeListing.pending]: (state, action) => {
      state.isburnBeforeListingProgress = true;
    },
    [burnBeforeListing.fulfilled]: (state, action) => {
      state.isburnBeforeListingProgress = false;
      state.isburnBeforeListingComplete = true;
    },
    [burnBeforeListing.rejected]: (state, action) => {
      state.isburnBeforeListingProgress = false;

      state.isburnBeforeListingRejected = true;
    },
  },
  reducers: {
    clearBurnNft: (state) => {
      state.isburnNftingInProgress = true;
      state.isburnNftingComplete = true;
      state.isburnNftingRejected = false;
      state.isburnBeforeListingProgress = false;
      state.isburnBeforeListingComplete = false;
      state.isburnBeforeListingRejected = false;
    },
  },
});
export const { clearBurnNft } = burnNftSlice.actions;
export default burnNftSlice.reducer;
