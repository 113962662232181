import {
  cancelListingPrice,
  putOnSale,
  updateListingPrice,
} from "../../Http/api";
import { TX_STATUS } from "../../components/modals/transactionModal/constants";
import { getPolygonTxFees } from "../../utils/utils";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const createOrder = createAsyncThunk(
  "listing/createOrder",
  async (payload, { dispatch, getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;
    const { web3Instance } = getState().Web3Instance;
    const { price, tokenId, setTxInfo } = payload;
    try {
      setTxInfo({
        txStatus: TX_STATUS.PENDING,
        txMessage: "Please accept tx from wallet",
      });
      const priceInWei = web3Instance.utils.toWei(price.toString(), "ether");

      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );

          const gasLimit = await marketplaceInstance.methods
            .createOrder(tokenId, priceInWei)
            .estimateGas({ from: connectedWallet });

          marketplaceInstance.methods
            .createOrder(tokenId, priceInWei)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })

            .once("transactionHash", function (txHash) {
              // this block will be executed once the transaction has been accept from wallet by the user
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;
              const orderId = receipt.events.Order.returnValues.orderId;
              try {
                const fd = new FormData();

                fd.append("tokenId", tokenId);
                fd.append("ownerAddress", connectedWallet);
                fd.append("transactionHash", txHash);
                fd.append("orderId", orderId);
                fd.append("price", price);
                const apiRes = await putOnSale(fd);
                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt, apiRes });
              } catch (err) {
                reject(err);
              }
            })
            .on("error", reject);
        } catch (err) {
          reject(err);
        }
      });
    } catch (err) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: err.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });

      throw err;
    }
  }
);

export const cancelListing = createAsyncThunk(
  "listing/cancelListing",
  async (payload, { getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;
    const { orderId, setTxInfo } = payload;
    try {
      setTxInfo({
        txStatus: TX_STATUS.PENDING,
        txMessage: "Please accept tx from wallet",
      });
      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const gasLimit = await marketplaceInstance.methods
            .removeOrder(orderId)
            .estimateGas({ from: connectedWallet });

          marketplaceInstance.methods
            .removeOrder(orderId)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })
            .once("transactionHash", function (txHash) {
              //tx hash for cancel selling bft
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              try {
                const { orderId, tokenId } =
                  receipt.events.OrderRemoved.returnValues;
                const txHash = receipt.transactionHash;
                const fd = new FormData();
                fd.append("tokenId", tokenId);
                fd.append("orderId", orderId);
                const apiResp = await cancelListingPrice(fd);
                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt, apiResp });
              } catch (error) {
                reject(error);
              }
            })
            .on("error", reject);
        } catch (err) {
          reject(err);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      throw error;
    }
  }
);

export const updateOrder = createAsyncThunk(
  "listing/updateOrder",

  async (payload, { dispatch, getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;
    const { web3Instance } = getState().Web3Instance;
    const { price, orderId, setTxInfo } = payload;

    try {
      setTxInfo({
        txStatus: TX_STATUS.PENDING,
        txMessage: "Please accept tx from wallet",
      });
      const priceInWei = web3Instance.utils.toWei(price.toString(), "ether");
      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const gasLimit = await marketplaceInstance.methods
            .modifyOrderPrice(orderId, priceInWei)
            .estimateGas({ from: connectedWallet });

          marketplaceInstance.methods
            .modifyOrderPrice(orderId, priceInWei)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })
            .once("transactionHash", function (txHash) {
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;
              const orderId = receipt.events.Order.returnValues.orderId;

              try {
                const fd = new FormData();
                fd.append("price", price);
                fd.append("orderId", orderId);
                fd.append("transactionHash", txHash);

                const apiRes = await updateListingPrice(fd);

                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt, apiRes });
              } catch (error) {
                reject(error);
              }
            })
            .on("error", reject);
        } catch (err) {
          reject(err);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });

      throw error;
    }
  }
);
