import { makeStyles, TextField } from "@material-ui/core";

const Input = (props) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      className={classes.input}
      variant="outlined"
      inputProps={{ style: { color: "white" } }}
    />
  );
};
export default Input;

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",

    "& label.Mui-focused": {
      color: "white",
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        padding: "9px",
      },
      "& fieldset": {
        borderColor: "white",
        borderWidth: "2px",
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
}));
