import { Box } from "@material-ui/core";
import React from "react";
import Mapaccordion from "../../components/FAQs/Mapaccordion";
import { makeStyles } from "@material-ui/core";

function FAQs() {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Box className={classes.subtextsection}>
        {/* <Typography variant="h2" className={classes.maintext}>
          Frequently Asked Questions
        </Typography> */}
      </Box>
      <Box className={classes.mapsection}>
        <Mapaccordion />
      </Box>
    </Box>
  );
}

export default FAQs;

const useStyles = makeStyles((theme) => ({
  main: {
    padding: " 10px 0px",
    backgroundColor: theme.palette.background.primary,
  },
  subtextsection: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  maintext: {
    paddingTop: "50px",
    marginBottom: "50px",
    color: theme.palette.text.primary,
    fontSize: "64px",
    lineHeight: " 74px",
    [theme.breakpoints.down("md")]: {
      padding: "45px",
      fontSize: "34px",
    },
  },
  mapsection: {
    padding: " 90px 0px",
    width: "80vw",
    margin: "auto",
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
  },
}));
