import { createAsyncThunk } from "@reduxjs/toolkit";
import { chainIdToNetworkInfo } from "../web3/constants";
import { getContractInstances } from "./constatns";

export const initializeContracts = createAsyncThunk(
  "contract/initializeContracts",
  async (_, { getState }) => {
    const {
      Web3Instance: { web3Instance },

      walletSlicer: { connectedChainId },
    } = getState();

    const {
      contracts: { erc721Address, auctionAddress, marketplaceAddress },
    } = chainIdToNetworkInfo[connectedChainId];

    return getContractInstances({
      web3Instance: web3Instance,
      erc721Address,
      auctionAddress,
      marketplaceAddress,
    });
  }
);
