import { createAsyncThunk } from "@reduxjs/toolkit";
import { artHistoryApi, getSingleNftData } from "../../Http/api";
export const singleNftDetails = createAsyncThunk(
  "sigleNft/getSingleNft",
  async (payload) => {
    try {
      const { data } = await getSingleNftData(payload);

      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const artHistory = createAsyncThunk(
  "singleNft/artHistory",
  async (payload) => {
    const { data } = await artHistoryApi(payload);
    return data;
  }
);
