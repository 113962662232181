const white = "#FFFFFF";
const black = "#e8ebe5";
const palette = {
  black,
  white,
  primary: {
    contrastText: black,
    // darkblue
    main: "#03228f",
  },
  secondary: {
    contrastText: white,
    // white
    main: "#ffff",
  },
  main: {
    // lightblue
    primary: "#3FA7DA",
  },
  text: {
    // off black
    primary: "#FFFFFF",
    // white
    secondary: "#828282",
    // white-grey
    placeholder: "#CACACA",

    blue: "#2278D4",
    hoverColor: "#005084",
  },
  background: {
    // lightgrey
    primary: "#002D42",
    // blue
    secondary: "#081A22",
    // lightblue
    blue: "#2278D4",
    // orange
    yellow: "yellow",
    main: "#EB9A28",
    alpha: "#4A9F42",
  },
};

export default palette;
