import { createAsyncThunk } from "@reduxjs/toolkit";
import { TX_APPROVAL_STATUS } from "../../components/modals/approvalModal/constants";
import { TX_STATUS } from "../../components/modals/transactionModal/constants";
import { uploadImagesApi } from "../../Http/api";

export const uploadImages = createAsyncThunk(
  "images/uploadImages",
  async (payload) => {
    const { setTxInfo, fd } = payload;
    try {
      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Images are being uploaded",
      });
      const res = await uploadImagesApi(fd);
      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.SUCCESS,
        txMessage: "Images Uploaded",
      });
      return res.data;
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Image upload failed",
        txHash: null,
      });
      throw error;
    }
  }
);
