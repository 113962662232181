import React, { useEffect } from "react";

import {
  CircularProgress,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { TX_APPROVAL_STATUS } from "./constants";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import Dialog from "../../Dialog";
import CustomButton from "../../buttons";

const ApprovalModal = ({
  txInfo,
  onClose = () => null,
  onExited = () => null,
}) => {
  const classes = useStyles();

  const { txStatus, txMessage } = txInfo;
  useEffect(() => {
    if (txStatus === TX_APPROVAL_STATUS.SUCCESS) {
      onClose();
    }
  }, [txStatus, onClose]);

  return (
    <Dialog
      open={Boolean(txMessage)}
      TransitionProps={{
        onExited,
      }}
    >
      <DialogContent className={classes.modalMainTransaction}>
        {TX_APPROVAL_STATUS.PENDING === txStatus && (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              You're granting access to all the NFTs on this contract, including
              any you might own in the future. The party on the other end can
              transfer NFTs from your wallet at any time without asking you
              until you revoke this approval. Proceed with caution.
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
              {txMessage}
            </Typography>

            <CircularProgress className={classes.transactionLoading} />
          </>
        )}
        {TX_APPROVAL_STATUS.REJECTED === txStatus && (
          <>
            <Typography variant="h3" align="center" gutterBottom>
              Transaction Rejected
            </Typography>
            <Typography variant="h4" align="center" gutterBottom>
              {txMessage}
            </Typography>
            <WarningRoundedIcon className={classes.deninedIcon} />
          </>
        )}
        {(TX_APPROVAL_STATUS.REJECTED === txStatus ||
          TX_APPROVAL_STATUS.SUCCESS === txStatus) && (
          <CustomButton
            variant="contained"
            style={{ marginTop: "20px" }}
            onClick={onClose}
          >
            Close
          </CustomButton>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ApprovalModal;

const useStyles = makeStyles((theme) => ({
  modalMainTransaction: {
    padding: "40px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  transactionLoading: {
    marginTop: "20px",
    color: theme.palette.secondary.main,
  },

  deninedIcon: {
    color: theme.palette.secondary.main,
    width: "100px",
    height: "70px",
  },

  txHashText: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "all 0.2s",
    marginTop: "1.2rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
