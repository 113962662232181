import { useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectWallet,
  getNetworkInfo,
  hasRole,
} from "../redux/wallet/wallet.actions";
import { ACCEPTED_CHAIN_IDs } from "../redux/web3/constants";
import { initializeWeb3Packages } from "../redux/web3/web3.actions";
import { updateNetworkInfo } from "../redux/wallet/wallet.slicer";
import { initializeContracts } from "../redux/contract/contract.actions";
import { useWeb3Functions } from "./web3.functions";

export const useInitializeWeb3Packages = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(initializeWeb3Packages());
  }, [dispatch]);
};

// checks the wallet connection status and update state if wallet is connected on page refresh
export const useCheckWalletConnection = () => {
  const hasBeenChecked = useRef(false);
  const { isUserLogginInSuccess } = useSelector((state) => state.auth);
  const { web3PackagesLoaded } = useSelector((state) => state.Web3Instance);

  const { onWalletConnect } = useWeb3Functions();

  useEffect(() => {
    const isConnected = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER");
    if (
      isUserLogginInSuccess &&
      web3PackagesLoaded &&
      isConnected &&
      !hasBeenChecked.current
    ) {
      onWalletConnect();
      hasBeenChecked.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3PackagesLoaded]);
};

// chain and wallet address change event listners for wallet
export const useOnProviderChange = () => {
  const { provider } = useSelector((state) => state.Web3Instance);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!provider) return;
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      dispatch(connectWallet());
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      let isCorrectNetwork = false;
      if (ACCEPTED_CHAIN_IDs.includes(chainId)) isCorrectNetwork = true;
      dispatch(
        updateNetworkInfo({
          connectedChainId: chainId,
          isCorrectNetwork,
        })
      );
    });

    dispatch(getNetworkInfo());
  }, [provider, dispatch]);
};

export const useInitializeContracts = () => {
  const dispatch = useDispatch();
  const { web3PackagesLoaded } = useSelector((state) => state.Web3Instance);
  const { isCorrectNetwork, connectedChainId } = useSelector(
    (state) => state.walletSlicer
  );
  useEffect(() => {
    isCorrectNetwork && web3PackagesLoaded && dispatch(initializeContracts());
  }, [isCorrectNetwork, web3PackagesLoaded, connectedChainId, dispatch]);
};

export const UseCheckForAdminRole = () => {
  const { isContractInitialized } = useSelector((state) => state.Contracts);
  const { isWalletConnected, connectedWallet } = useSelector(
    (state) => state.walletSlicer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      hasRole({
        role: "0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775",
        address: connectedWallet,
      })
    );
  }, [isContractInitialized, isWalletConnected, connectedWallet, dispatch]);
};
