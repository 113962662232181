import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginUser } from "./apis";

export const userLogin = createAsyncThunk("user/login", async (payload) => {
  const { data } = await loginUser(payload);
  return data;
});

export const isUserOnValidChain = createAsyncThunk(
  "user/isOnValidChain",
  async (_, { getState }) => {}
);
