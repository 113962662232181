import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import CustomButton from "../buttons";
import SvgIcon from "../icons";
import PersonOutlineRoundedIcon from "@material-ui/icons/PersonOutlineRounded";

export default function Drawer() {
  const classes = useStyles();
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={classes.navLinks}>
        <Link className={classes.links} to="/">
          Market
        </Link>
        <Link className={classes.links} to="/">
          FAQs
        </Link>
        <Link className={classes.links} to="/">
          About Us
        </Link>
      </div>
      <div className={classes.userBox}>
        <CustomButton children="connect now" variant="outlined" />
        <div className={classes.userIconBox}>
          <SvgIcon circle className={classes.circleIcon} />
          <PersonOutlineRoundedIcon className={classes.userIcon} />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button className={classes.btn} onClick={toggleDrawer(anchor, true)}>
            <MenuIcon />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    height: "100vh",
    padding: "20px 30px",
    background: theme.palette.background.secondary,
  },

  navLinks: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    flexDirection: "column",
  },
  links: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontWeight: "600",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },

  userIconBox: {
    position: "relative",
  },
  circleIcon: {
    width: "52px",
  },
  userIcon: {
    position: "absolute",
    left: 1,
    fontSize: "50px",
    color: theme.palette.text.primary,
  },
  btn: {
    "&.MuiButton-root": {
      minWidth: 0,
    },
  },
}));
