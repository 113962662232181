import { createSlice } from "@reduxjs/toolkit";
import { uploadImages } from "./uploadImages.actions";

const initialState = {
  isImageUploadingInProgress: false,
  isImageUploadingComplete: false,
  isImageUploadingRejected: false,
};

const uploadImagesSlice = createSlice({
  name: "uploadImagesSlice",
  initialState,

  extraReducers: {
    [uploadImages.pending]: (state, action) => {
      state.isImageUploadingInProgress = true;

      state.isImageUploadingComplete = false;

      state.isImageUploadingRejected = false;
    },
    [uploadImages.fulfilled]: (state, action) => {
      state.isImageUploadingInProgress = false;
      state.isImageUploadingComplete = true;
      state.isImageUploadingRejected = false;
    },
    [uploadImages.rejected]: (state, action) => {
      state.isImageUploadingInProgress = false;
      state.isImageUploadingComplete = false;
      state.isImageUploadingRejected = true;
    },
  },
  reducers: {
    resetOrderState: (state) => {
      state.isImageUploadingInProgress = false;
      state.isImageUploadingComplete = false;
      state.isImageUploadingRejected = false;
    },
  },
});
export const { resetOrderState } = uploadImagesSlice.actions;
export default uploadImagesSlice.reducer;
