import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Card, { NftCardSkelton } from "../card";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomButton from "../buttons";
import { sortNftsByOption } from "../../helper";

const FeaturedNfts = ({ sortOption }) => {
  const classes = useStyles();
  const [showRows, setShowRows] = useState(4);
  const [showIcon, setShowIcon] = useState(false);
  const { isgetFeatureArtsGetting, featureArts } = useSelector(
    (state) => state.FeatureArts
  );

  const ClickHandler = () => {
    const totalPages = featureArts.length;

    setShowRows((prev) => prev + 4);

    if (totalPages < showRows) {
      setShowIcon(true);
    }
  };
  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title}>Featured Products</Typography>
      </div>
      <div className={classes.cardBox}>
        {featureArts &&
          featureArts.slice(0, showRows).map((item) => {
            return <Card viewBtn={false} item={item} />;
          })}

        {isgetFeatureArtsGetting &&
          Array(8)
            .fill(1)
            ?.map((item, i) => <NftCardSkelton key={i} />)}
      </div>
      {!showIcon && (
        <div className={classes.btn}>
          <CustomButton variant="contained" onClick={ClickHandler}>
            Load More
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default FeaturedNfts;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.primary,
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  title: {
    fontSize: "38px",
    color: theme.palette.text.primary,
    fontWeight: 800,
  },
  cardBox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    // gridColumnGap: "33px",
    gridRowGap: "33px",
    marginTop: "33px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: " 1fr 1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: " 1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: " 1fr",
      justifyContent: "center",
    },
  },
  btn: {
    alignSelf: "center",
    marginTop: 28,
    borderRadius: 10,
  },
}));
