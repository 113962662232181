import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showBurnNft: false,
  nftdeliver: false,
  resell: false,
  placeorder: false,
  wrongWallet: false,
  showConnectWalletModal: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    toggleshowBurnNft: (state, action) => {
      state.showBurnNft = action.payload;
    },
    togglenftDeliver: (state, action) => {
      state.nftdeliver = action.payload;
    },
    toggleRecell: (state, action) => {
      state.resell = action.payload;
    },
    toggleplaceorder: (state, action) => {
      state.placeorder = action.payload;
    },
    toggleWrongWallet: (state, action) => {
      state.wrongWallet = action.payload;
    },
    toogleConnecWallet: (state, action) => {
      state.showConnectWalletModal = action.payload;
    },
  },
});

export const {
  toggleshowBurnNft,
  togglenftDeliver,
  toggleRecell,
  toggleplaceorder,
  toggleWrongWallet,
  toogleConnecWallet,
} = loginSlice.actions;
export default loginSlice.reducer;
