const Data = [
  {
    Question: "What is an NFT?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "how do NFTS work?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "what can NFT used for?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "how do i make NFTS?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "What is an NFT?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "What is an NFT?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "HOW do i buy NFTS?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
  {
    Question: "what can NFT used for?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },

  {
    Question: "What is Metamask?",
    Answer: `NFTs are tokens that we can use to represent ownership of unique items. They let us tokenise things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain  no one can modify the record of ownership or copy/paste a new NFT into existence.
      NFT stands for non-fungible token. Non-fungible is an economic term that you could use to describe things like your furniture, a song file, or your computer. These things are not interchangeable for other items because they have unique properties.`,
  },
];
export default Data;
