import { Avatar, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MenuListComposition from "../menuList";
import { connectWallet } from "../../redux/wallet/wallet.actions";

import CustomButton from "../buttons";
import { baseProfileUrl } from "../../Http/config";
import { useEffect } from "react";
import { toggleWrongWallet } from "../../redux/login/login.slicer";

const UserWalletConnection = () => {
  const dispatch = useDispatch();
  const {
    user: { userExist },
    isUserLogginInSuccess,
  } = useSelector((state) => state.auth);
  const openConnectModal = () => {
    dispatch(connectWallet());
  };

  useEffect(() => {
    if (Boolean(!userExist) && isUserLogginInSuccess)
      dispatch(toggleWrongWallet(true));
  }, [userExist, dispatch, isUserLogginInSuccess]);
  return (
    <>
      {Boolean(userExist) && <UserConnectedStatus />}

      {Boolean(!userExist) && (
        <CustomButton
          onClick={openConnectModal}
          children="connect now"
          variant="contained"
          style={{ width: "125px" }}
        />
      )}
    </>
  );
};

export default UserWalletConnection;

const UserConnectedStatus = () => {
  const { userName, image } = useSelector((state) => state.auth.user);
  const textHandler = (item) => {
    if (item.length > 10) {
      return item.substring(0, 10).concat("...");
    }
    return item;
  };
  return (
    <MenuListComposition>
      <div className="buttonsContainer">
        <Typography
          style={{ paddingRight: "5px", fontStyle: "italic" }}
          variant="h4"
        >
          @{textHandler(userName)}
        </Typography>
        <div className="avatarContainer">
          <Avatar src={baseProfileUrl + image} />
        </div>
      </div>
    </MenuListComposition>
  );
};
