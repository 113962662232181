import { createAsyncThunk } from "@reduxjs/toolkit";
import { headerSearch } from "../../Http/api";

export const getSearchResults = createAsyncThunk(
  "search/getHeaderSearch",
  async (payload) => {
    try {
      const { data } = await headerSearch(payload);

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
