import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../components/buttons";
import ImageUpload from "../../components/drapAndDrop";
import Input from "../../components/input";
import RegisterInput from "../../components/Register";
import { nftSchema } from "./schemas";
import TransactionModal from "../../components/modals/transactionModal";
import { mint } from "../../redux/mint/mint.actions";
import { useDispatch, useSelector } from "react-redux";
import { resetMintingState } from "../../redux/mint/mint.slice";
import { getNetworkInfo } from "../../redux/wallet/wallet.actions";
import { useIsUserAuthenticated } from "../../utils/utils";

const TITLE_LIMIT = 50;
const MintPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAdmin } = useSelector((state) => state.walletSlicer);
  const { isCorrectNetwork } = useSelector((state) => state.walletSlicer);
  const isUserAuthenticated = useIsUserAuthenticated();
  const initialValues = {
    title: "",

    description: "",
    file: "",

    agree: false,
  };
  useEffect(() => {
    if (!isAdmin) {
      navigate(-1);
    }
  }, [isAdmin, navigate, isCorrectNetwork, dispatch]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: nftSchema,
    onSubmit: (values) => {
      const status = isUserAuthenticated();
      if (status) dispatch(mint({ ...values, setTxInfo }));
    },
  });

  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });
  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
  };

  const { isMintingComplete, tokenId } = useSelector((state) => state.mint);

  const onTxModalExited = () => {
    isMintingComplete && navigate(`/nft-detail/${tokenId}`);

    dispatch(resetMintingState());
  };

  return (
    <div className={classes.container}>
      <TransactionModal
        txInfo={txInfo}
        onClose={onTxModalClose}
        onExited={onTxModalExited}
      />
      <div className={classes.imgUplaod}>
        <ImageUpload
          formik={formik}
          value={formik.values.file}
          helperText={(formik.touched.file && formik.errors.file) || `&nbsp`}
          error={formik.touched.file && Boolean(formik.errors.file)}
        />
      </div>
      <div className={classes.detailsDiv}>
        <Typography className={classes.title}>Mint item</Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <RegisterInput
            label="Title"
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            helperText={
              (formik.touched.title && formik.errors.title) ||
              `${formik.values.title.length}/${TITLE_LIMIT}`
            }
            error={formik.touched.title && Boolean(formik.errors.title)}
          />

          <textarea
            placeholder="description"
            className={classes.textArea}
            rows="10"
            onChange={formik.handleChange}
            value={formik.values.description}
            formik={formik}
            name="description"
            helperText={
              (formik.touched.description && formik.errors.description) ||
              `&nbsp;`
            }
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
          />
          <div className={classes.checkDiv}>
            <Checkbox
              checked={formik.values.agree}
              onChange={formik.handleChange}
              name="agree"
              className={
                formik.touched.agree &&
                Boolean(formik.errors.agree) &&
                classes.errormessage
              }
            />

            <Typography
              className={
                formik.touched.agree && Boolean(formik.errors.agree)
                  ? classes.errormessage
                  : classes.agrement
              }
            >
              i agree to the
              <Link
                to="/termsandconditions"
                className={
                  formik.touched.agree && Boolean(formik.errors.agree)
                    ? classes.errormessage
                    : classes.link
                }
              >
                terms and conditions
              </Link>
            </Typography>
          </div>
          <Typography className={classes.typo}>
            In publishing and graphic design, Lorem ipsum Lorem ipsum dolor sit
            amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua. Ut enim ad minim
          </Typography>
          <div className={classes.btnBox}>
            <CustomButton
              variant="outlined"
              children="create item"
              className={classes.btnWhite}
              onClick={formik.submitForm}
            />
            <CustomButton
              variant="outlined"
              children="RESET FORM"
              className={classes.btnTransp}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default MintPage;
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.primary,
    padding: "108px 28px",
    display: "grid",
    gridTemplateColumns: "1.5fr 2fr",
    // justifyItems: "center",
    alignItems: "center",
    gap: 50,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  imgUplaod: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  detailsDiv: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  input: {
    borderBottom: "1px solid white",
    color: theme.palette.text.primary,
    fontSize: 20,
    "&::placeholder": {
      color: theme.palette.text.primary,
      opacity: "1",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 800,
    marginBottom: 20,
  },
  textArea: {
    resize: "none",
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    outline: "none",
    border: " 2.5078px solid #FFFFFF",
    borderRadius: "14.5004px",
    padding: 23,

    "&::placeholder": {
      color: theme.palette.text.primary,
      opacity: "1",
    },
  },
  checkDiv: {
    display: "flex",
    gap: 15,
    alignItems: "center",
  },
  checkbox: {
    "& .MuiIconButton-label": {
      fontSize: 24,
      width: 20,
      height: 20,
      borderRadius: 3,
      "& .MuiSvgIcon-root": {
        fill: "#fff",
      },
    },
  },
  agrement: {
    color: theme.palette.text.primary,
  },
  link: {
    color: "#2278D4",
    textDecoration: "none",
  },
  typo: {
    color: theme.palette.text.primary,
    fontSize: 17,
  },
  btnBox: {
    display: "flex",
    gap: 22,
  },
  btnWhite: {
    flexGrow: 1,
    backgroundColor: "white",
    color: "#2278D4",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  btnTransp: {
    flexGrow: 1,
  },
  errormessage: {
    color: "red",
  },
}));
