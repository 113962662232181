import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import ScrollToTop from "../components/scrollToTop";
import AboutUs from "../pages/about";

import DetailsPage from "../pages/detailsPage";
import EditProfile from "../pages/editprofile";
import FAQs from "../pages/FAQs/FAQs";
import Home from "../pages/home";
import Market from "../pages/market";
import MintPage from "../pages/mintPage";
import UploadProductImages from "../pages/uploadProImag";

import UserProfile from "../pages/userProfile";
import { getNetworkInfo } from "../redux/wallet/wallet.actions";
import UniversalDialogs from "../UniversalDialogs";

const Routers = () => {
  const dispatch = useDispatch();

  return (
    <Router>
      <UniversalDialogs />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/nft-detail/:token" element={<DetailsPage />} />
        <Route exact path="/userprofile/:wallet" element={<UserProfile />} />

        <Route exact path="/mintpage" element={<MintPage />} />
        <Route exact path="/faqs" element={<FAQs />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/market" element={<Market />} />
        <Route
          exact
          path="/upload-product-images"
          element={<UploadProductImages />}
        />

        <Route exact path="/editprofile/:wallet" element={<EditProfile />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default Routers;
