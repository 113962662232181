import React from "react";
import {
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import vector from "../../assets/images/profileAvatar.webp";
import { getIpfsUrl, whatIsFileType } from "../../helper";

import { baseProfileUrl } from "../../Http/config";
import { useNavigate } from "react-router-dom";

const SearchDropDown = ({ close = () => {} }) => {
  const {
    HeaderSearch: {
      isSearchResultLoading,
      isSearchResultLoaded,

      artWorks,
      creators,
    },
  } = useSelector((state) => state);
  const classes = useStyles();

  const noMatch = artWorks.length === 0 && creators.length === 0;

  if (!isSearchResultLoading && isSearchResultLoaded && noMatch)
    return (
      <Paper className={classes.searchedpaper} elevation={3}>
        <Typography className={classes.nomatchtext}>No Match Found</Typography>
      </Paper>
    );

  return (
    <>
      {isSearchResultLoading ? (
        <SkeletonLoading />
      ) : (
        !noMatch && (
          <Paper className={classes.searchedpaper} elevation={3}>
            <List className={classes.root} subheader={<li />}>
              <li className={classes.listSection}>
                <StcikyListCompo
                  heading="Artworks"
                  data={artWorks}
                  close={close}
                />
                <StcikyListCompo
                  heading="Artist"
                  data={creators}
                  close={close}
                />
              </li>
            </List>
          </Paper>
        )
      )}
    </>
  );
};

export default SearchDropDown;

export const StcikyListCompo = ({
  heading,
  data,

  close,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleNavigation = (heading, id, walletAddress) => {
    if (heading === "Artworks") {
      navigate("/nft-detail/" + id);
    } else if (heading === "Artist") {
      navigate("/userprofile/" + walletAddress);
    }
    close();
  };

  return (
    <>
      {Boolean(data.length) && (
        <ul className={classes.ul}>
          <ListSubheader className={classes.listheading}>
            {heading}
            <Divider className={classes.divider} />
          </ListSubheader>

          <div className={classes.serachedrow}>
            {data.map((item, key) => {
              return (
                <ListItem
                  key={key}
                  onClick={() => {
                    handleNavigation(
                      heading,

                      item.tokenId,
                      item.walletAddress
                    );
                  }}
                >
                  <>
                    <div className={classes.serchedImage}>
                      {heading === "Artworks" ? (
                        whatIsFileType(item.artImage) === "image" ? (
                          <img
                            alt={item.title}
                            src={
                              item.artImage
                                ? item.artImage && getIpfsUrl(item.artImage)
                                : vector
                            }
                            className={classes.userimage}
                          />
                        ) : (
                          <video
                            src={getIpfsUrl(item.artImage)}
                            loop
                            mute
                            autoPlay
                            playsInline
                            controls
                            className={classes.media}
                            // onClick={handleVideoClick}
                          />
                        )
                      ) : (
                        <img
                          alt={item.username}
                          src={item.img ? baseProfileUrl + item.img : vector}
                          className={classes.userimage}
                        />
                      )}
                    </div>
                    <div className={classes.serchedData}>
                      <Typography variant="h5">
                        {item.title || item.username}
                      </Typography>
                    </div>
                  </>
                </ListItem>
              );
            })}
          </div>
        </ul>
      )}
    </>
  );
};

const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.searchedpaper} elevation={3}>
      {["Artworks", "Artists"].map((heading) => (
        <SkeletonLoadingUnit heading={heading} />
      ))}
    </Paper>
  );
};

const SkeletonLoadingUnit = ({ heading }) => {
  const classes = useStyles();
  return (
    <ul style={{ padding: "0px" }}>
      <ListSubheader className={classes.listheading}>{heading}</ListSubheader>

      <Divider variant="middle" />
      <div className={classes.serachedrow}>
        <div className={classes.skelton}>
          <div className={classes.serchedImage}>
            <Skeleton variant="circle" width={40} height={40} />
          </div>
          <div className={classes.serchedData}>
            <Skeleton animation="wave" width="50%" />
            <Skeleton animation="wave" width="80%" />
          </div>
        </div>
      </div>
    </ul>
  );
};

const useStyles = makeStyles((theme) => ({
  searchedpaper: {
    position: "absolute",
    maxWidth: "300px",
    minWidth: "300px",
    borderRadius: "10px",

    top: 82,
    zIndex: 1000,
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.up("xl")]: {
      left: "300px",
      width: "50%",
    },
    padding: "0px 10px",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      // "-webkit-box-shadow": "inset 0 0 10px rgba(0,0,0,0.2)",
      margin: "15px 0px",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
    },
  },
  nomatchtext: {
    padding: "30px",
    textAlign: "center",
  },
  root: {
    position: "relative",

    overflow: "auto",
    maxHeight: 300,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      // "-webkit-box-shadow": "inset 0 0 10px rgba(0,0,0,0.2)",
      margin: "15px 0px",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ccc",
      borderRadius: "10px",
    },
  },
  listSection: {
    backgroundColor: "inherit",
  },

  ul: {
    padding: 0,
  },
  userimage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  serachedrow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    minWidth: "100%",
    padding: "10px",
  },
  serchedData: {
    paddingLeft: "10px",
    color: "var(--secondary-color)",
    fontWeight: "light",
    width: "100%",
  },
  listheading: {
    height: "max-content",
  },
  skelton: {
    display: "flex",
    width: "100%",
  },

  divider: {
    backgroundColor: "white",
  },
  media: {
    objectFit: "cover",
    width: "50px",
    height: "40px",
    maxWidth: "100%",
    cursor: "pointer",

    borderRadius: "50%",
  },
}));
