import {
  Box,
  DialogContent,
  IconButton,
  Input,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "../Dialog";
import CancelIcon from "@material-ui/icons/Cancel";

import CardPopUp from "../popupCard";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import CustomButton from "../buttons";

export default function ReSellNftModal({ showModal, setShowModal, details }) {
  const classes = useStyles();

  const handleClose = () => {
    setShowModal({
      ...showModal,
      reSellNftModal: false,
    });
  };
  return (
    <Dialog open={showModal.reSellNftModal} className={classes.loginMain}>
      <ModalContent handleClose={handleClose} details={details} />
    </Dialog>
  );
}

const ModalContent = ({ handleClose, details }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleClose}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <Box className={classes.loginGrid}>
          <Box className={classes.imageContainer}>
            <Typography className="typoo" variant="h2">
              Re
            </Typography>
            <Typography className="typoo1" variant="h2">
              Sell
            </Typography>
          </Box>

          <Box className={classes.endContainer}>
            <Box>
              <CardPopUp details={details} />
            </Box>
            <Box className={classes.secondSection}>
              <Typography Variant="h1" className="typoo1">
                Price
              </Typography>
              <Box className={classes.priceBox}>
                <Input
                  placeholder="price"
                  className={classes.price}
                  disableUnderline
                  type="number"
                />
                <Box style={{ display: "flex", gap: 5 }}>
                  <AllInclusiveIcon className={classes.iconn} />
                  <Typography variant="body1" style={{ paddingTop: 3 }}>
                    {" "}
                    matic
                  </Typography>
                </Box>
              </Box>
              <div className={classes.checkSec}>
                <Checkbox
                  className="checkbox"
                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                />
                <Box className={classes.checktext}>
                  <Typography variant="body1">
                    {" "}
                    i agree to list this NFT to the{" "}
                  </Typography>
                  <Typography variant="body1" className="text">
                    marketplace
                  </Typography>
                </Box>
              </div>
              <Box className={classes.buttonSec}>
                <CustomButton variant="outlined" className="button">
                  {" "}
                  resell
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },
  loginMain: {
    "& .MuiPaper-root": {
      marginTop: 80,
      width: "100%",

      maxWidth: "60%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100% - 100px)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },

      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },

  imageContainer: {
    textTransform: "capitalize",
    display: "flex",
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 60,

    " & .typoo1": {
      color: theme.palette.background.blue,
    },
  },

  endContainer: {
    padding: "20px 40px",
    marginTop: 20,
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    placeContent: "center",
    justifyContent: "center",
    gap: 15,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr ",
    },

    "& .innerCont": {
      width: 300,
      height: 60,

      borderRadius: 50,
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },

  secondSection: {
    paddingTop: 60,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
    },

    "& .typoo1": {
      paddingLeft: 8,
      textTransform: "upperCase",
      fontSize: 27,
      color: theme.palette.text.primary,
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 20,
      },
    },
  },

  priceBox: {
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "2px solid" + theme.palette.text.primary,
    borderRadius: 15,
    height: 25,
    width: 350,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  checkSec: {
    paddingTop: 20,
    display: "flex",

    "& .MuiCheckbox-root": { color: theme.palette.text.primary },
  },

  checktext: {
    textTransform: "capitalize",
    paddingTop: 10,
    display: "flex",
    wordSpacing: 4,
    gap: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      wordSpacing: 1,
    },

    "& .text": {
      color: theme.palette.background.blue,
      textTransform: "capital",
    },
  },

  buttonSec: {
    display: "flex",
    justifyContent: "end",
    " & .button": {
      width: 120,
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.blue,
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
      },
    },
  },

  iconn: {
    transform: "rotate(30deg)",
  },

  price: {},
}));
