import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import NativeSelect from "@material-ui/core/NativeSelect";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import SearchInput from "../searchInput";
import { makeStyles } from "@material-ui/core";

export default function SelectInput({ formik, value }) {
  const classes = useStyles();

  const handleChange = (val) => {
    formik.setFieldValue("country", val);
  };
  return (
    <CountryDropdown
      value={value}
      onChange={handleChange}
      className={classes.select}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.palette.background.primary,
    color: "white",
    padding: "7px",
    border: "2px solid white",
    borderRadius: "10px",
    width: "100%",
  },
}));
