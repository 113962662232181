import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorIcon from "@material-ui/icons/Error";
import { CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import CancelIcon from "@material-ui/icons/Cancel";

export default function ProgressSnackBar({
  open,
  autoHide,
  handleClose,
  success,
  message,
  error,
}) {
  const classes = useStyles();

  const menuStyle = clsx({
    [classes.loadingSnackBar]: open,
    [classes.successSnackbar]: success,
    [classes.errorSnackBar]: error,
  });

  return (
    <Snackbar
      className={classes.progress}
      ContentProps={{
        classes: {
          root: menuStyle,
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open || success || error}
      autoHideDuration={autoHide}
      message={message}
      action={
        <React.Fragment>
          {open ? (
            <>
              <CircularProgress color="white" />
            </>
          ) : (
            <CancelIcon onClick={handleClose} />
          )}
        </React.Fragment>
      }
    />
  );
}

const useStyles = makeStyles((theme) => ({
  loadingSnackBar: { backgroundColor: theme.palette.main.primary },
  successSnackbar: { backgroundColor: "green" },
  errorSnackBar: { backgroundColor: "red" },

  progress: {
    "& .MuiPaper-root": {
      borderRadius: "40px",
    },
  },
}));
