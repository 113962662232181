import {
  Box,
  DialogContent,
  IconButton,
  makeStyles,
  NativeSelect,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "../../Dialog";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { toggleplaceorder } from "../../../redux/login/login.slicer";
import CardPopUp from "../../popupCard";

import CustomButton from "../../buttons";

import CheckBox from "../../checkBox";
import Input from "../../input";
import SearchInput from "../../searchInput";
import SelectInput from "../../selectInput";
import { useStyles } from "./styles";
import { useFormik } from "formik";
import { orderScehema } from "./schema";
import { orderNft } from "../../../redux/nftOrder/nftOrder.actions";
import { useEffect, useState } from "react";

import TransactionModal from "../../modals/transactionModal";
import { useContractFunctions } from "../../../redux/contract/utils";
import ApprovalModal from "../../modals/approvalModal";
import axios from "axios";
import { useIsUserAuthenticated } from "../../../utils/utils";
import { getUserInWallet } from "../../../redux/userProfile/userProfile.actions";
import { useParams } from "react-router-dom";
import { resetOrderState } from "../../../redux/nftOrder/nftOrder.slice";
import { cancelNftRequest } from "../../../Http/api";
export default function OrderNftModal({ showModal, setShowModal, details }) {
  const classes = useStyles();

  const handleClose = () => {
    setShowModal({
      ...showModal,
      orderNftModal: false,
    });
  };

  return (
    <Dialog open={showModal.orderNftModal} className={classes.loginMain}>
      <ModalContent handleClose={handleClose} details={details} />
    </Dialog>
  );
}

const ModalContent = ({ handleClose, details }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { artPrice, ownerWallet, tokenId } = details;
  const { isorderNftingComplete, isorderNftingRejected } = useSelector(
    (state) => state.orderNft
  );
  const { wallet } = useParams();

  const [ip, setIP] = useState("");
  const isUserAuthenticated = useIsUserAuthenticated();
  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const [txInfoApproval, setTxInfoApproval] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });

  const onTxApprovalModalClose = () => {
    setTxInfoApproval({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
  };

  const onTxApprovalModalExited = () => {
    // if success
    // redirect
    // if  failure rest state
  };
  const { isMarketplaceApproved } = useContractFunctions({ setTxInfoApproval });
  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });
  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
    dispatch(resetOrderState());
    if (isorderNftingComplete) {
      dispatch(getUserInWallet(wallet));
      handleClose();
    } else if (isorderNftingRejected) {
      const fd = new FormData();
      fd.append("tokenId", tokenId);
      cancelNftRequest(fd);
      handleClose();
    }
  };

  const initialValues = {
    country: null,
    postalCode: "",
    contactNum: null,
    city: "",
    agree: false,
    deliveryAddress: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: orderScehema,
    onSubmit: async (values) => {
      const isAuth = isUserAuthenticated();
      const status =
        isAuth && (await isMarketplaceApproved({ setTxInfoApproval }));

      if (status && isAuth) {
        dispatch(
          orderNft({
            ...values,
            price: artPrice,
            ownerWallet,
            tokenId,
            setTxInfo,
            ip,
          })
        );
      }
    },
  });

  const onTxModalExited = () => {};

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <TransactionModal
          txInfo={txInfo}
          onClose={onTxModalClose}
          onExited={onTxModalExited}
        />
        <ApprovalModal
          txInfo={txInfoApproval}
          onClose={onTxApprovalModalClose}
          onExited={onTxApprovalModalExited}
        />

        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleClose}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <Box className={classes.loginGrid}>
          <Box className={classes.imageContainer}>
            <Typography className="typoo" variant="h2">
              place
            </Typography>
            <Typography className="typoo1" variant="h2">
              order
            </Typography>
          </Box>

          <Box className={classes.endContainer}>
            <Box>
              <CardPopUp details={details} />
            </Box>
            {Boolean(Number(details.isOrderPlaced)) ? (
              <Typography variant="h1" style={{ marginTop: "30px" }}>
                Order has been already placed
              </Typography>
            ) : (
              <form className={classes.secondSection}>
                <Box className={classes.innerSection}>
                  <div>
                    <Typography variant="h4" className={classes.TypographyHEAD}>
                      {" "}
                      country
                    </Typography>
                    <SelectInput
                      formik={formik}
                      value={formik.values.country}
                    />
                  </div>
                  <div>
                    <Typography variant="h5" className={classes.TypographyHEAD}>
                      {" "}
                      postal code (optional)
                    </Typography>
                    <Input
                      placeholder="text"
                      className={classes.search}
                      name="postalCode"
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.postalCode && formik.errors.postalCode
                      }
                      error={
                        formik.touched.postalCode &&
                        Boolean(formik.errors.postalCode)
                      }
                    />
                  </div>
                </Box>
                <Box className={classes.innerSection}>
                  <div>
                    <Typography variant="h5" className={classes.TypographyHEAD}>
                      {" "}
                      contact number
                    </Typography>
                    <Input
                      placeholder="12334444"
                      className={classes.search}
                      name="contactNum"
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.contactNum && formik.errors.contactNum
                      }
                      error={
                        formik.touched.contactNum &&
                        Boolean(formik.errors.contactNum)
                      }
                    />
                  </div>
                  <div>
                    <Typography variant="h5" className={classes.TypographyHEAD}>
                      {" "}
                      City
                    </Typography>
                    <Input
                      placeholder=" City"
                      className={classes.search}
                      name="city"
                      onChange={formik.handleChange}
                      helperText={formik.touched.city && formik.errors.city}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                    />
                  </div>
                </Box>
                <Box className={classes.lastSection}>
                  <div>
                    <Typography variant="h5" className={classes.TypographyHEAD}>
                      {" "}
                      delivery address
                    </Typography>
                    <Input
                      style={{ height: 50 }}
                      placeholder=" delivery address"
                      className={classes.searchend}
                      name="deliveryAddress"
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.deliveryAddress &&
                        formik.errors.deliveryAddress
                      }
                      error={
                        formik.touched.deliveryAddress &&
                        Boolean(formik.errors.deliveryAddress)
                      }
                    />
                  </div>
                </Box>
                <Box>
                  <Typography>Delivery Charges will be 0.2 Matic</Typography>
                </Box>
                <div className={classes.checkSec}>
                  <Checkbox
                    checked={formik.values.agree}
                    onChange={formik.handleChange}
                    name="agree"
                  />
                  <Box className={classes.checktext}>
                    <Typography
                      variant="body1"
                      className={
                        formik.touched.agree &&
                        Boolean(formik.errors.agree) &&
                        classes.errormessage
                      }
                    >
                      i agree to transfer this NFT to{" "}
                    </Typography>
                    <Typography variant="body1" className="text">
                      admin
                    </Typography>
                  </Box>
                </div>
                <Box className={classes.buttonSec}>
                  <CustomButton
                    variant="outlined"
                    className="button"
                    onClick={formik.submitForm}
                  >
                    {" "}
                    place order
                  </CustomButton>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};
