import {
  Box,
  CircularProgress,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Dialog from "../Dialog";

import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { toggleWrongWallet } from "../../redux/login/login.slicer";

import CustomButton from "../buttons";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/auth.slicer";

export default function ConnectingWallet() {
  const classes = useStyles();

  const { isUserLogginIn } = useSelector((state) => state.auth);

  return (
    <Dialog open={isUserLogginIn} className={classes.loginMain}>
      <ModalContent />
    </Dialog>
  );
}

const ModalContent = () => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <div className={classes.wrongWalletLower}>
          <Typography>connecting Wallet please wait</Typography>
          <div>
            <CircularProgress />
          </div>
        </div>
      </DialogContent>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },

  wrongWalletLower: {
    paddingTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    placeItems: "center",
    rowGap: "20px",
    padding: "30px 20px",
  },
}));
