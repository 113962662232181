import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";

import { getIpfsUrl } from "../../helper";

import ImageUpload from "../../components/drapAndDrop";
import CustomButton from "../../components/buttons";
import { useFormik } from "formik";
import { nftSchema } from "./schemas";
import { Toolbar } from "@material-ui/core";
import { useState } from "react";
import TransactionModal from "../../components/modals/transactionModal";
import { uploadImages } from "../../redux/uplaodRelatedImages/uploadImages.actions";
import { useNavigate } from "react-router-dom";

const UploadProductImages = () => {
  const {
    signleNft: { artImg, tokenId },
  } = useSelector((state) => state.singleNftDetails);
  const url = getIpfsUrl(artImg);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });

  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
    if (txInfo.txStatus === "SUCCESS") {
      navigate(`/nft-detail/${tokenId}`);
    }
  };

  const onTxModalExited = () => {};

  const initialValues = {
    image1: null,
    image2: null,
    image3: null,
    image4: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: nftSchema,
    onSubmit: (values) => {
      const fd = new FormData();
      fd.append("token_id", tokenId);
      fd.append("img1", values.image1);
      fd.append("img2", values.image2);
      fd.append("img3", values.image3);
      fd.append("img4", values.image4);

      dispatch(uploadImages({ fd, setTxInfo }));
    },
  });

  return (
    <div className={classes.uploadImagesSec}>
      <TransactionModal
        txInfo={txInfo}
        onClose={onTxModalClose}
        onExited={onTxModalExited}
      />
      <img src={url} className={classes.uplaodSecMainImage} alt="mainImage" />
      <form className={classes.uploadS} onSubmit={formik.handleSubmit}>
        <div className={classes.relImageUploadSec}>
          <ImageUpload
            className={classes.uploadSec}
            formik={formik}
            value={formik.values.image1}
            helperText={
              (formik.touched.image1 && formik.errors.image1) || `&nbsp`
            }
            error={formik.touched.image1 && Boolean(formik.errors.image1)}
            fileName="image1"
          />
          <ImageUpload
            formik={formik}
            value={formik.values.image2}
            helperText={
              (formik.touched.image2 && formik.errors.image2) || `&nbsp`
            }
            error={formik.touched.image2 && Boolean(formik.errors.image2)}
            fileName="image2"
          />
          <ImageUpload
            formik={formik}
            value={formik.values.image3}
            helperText={
              (formik.touched.image3 && formik.errors.image3) || `&nbsp`
            }
            error={formik.touched.image3 && Boolean(formik.errors.image3)}
            fileName="image3"
          />
          <ImageUpload
            formik={formik}
            value={formik.values.image4}
            helperText={
              (formik.touched.image4 && formik.errors.image4) || `&nbsp`
            }
            error={formik.touched.image4 && Boolean(formik.errors.image4)}
            fileName="image4"
          />
        </div>
        <Toolbar />
        <CustomButton
          variant="contained"
          children="Upload Image"
          className={classes.btnTransp}
          onClick={formik.submitForm}
        />
      </form>
    </div>
  );
};

export default UploadProductImages;

const useStyles = makeStyles(() => ({
  uploadImagesSec: {
    display: "grid",
    gridTemplateColumns: "4fr 8fr",
    padding: "30px 40px",
  },

  relImageUploadSec: {
    display: "grid",
    gridTemplateColumns: "250px 250px",
    gridTemplateRows: "150px 150px",

    rowGap: "90px",
    columnGap: "40px",
    justifyContent: "center",
  },
  uploadSec: {
    maxWidth: "100px",
    maxHeight: "100px",
  },

  btnTransp: {
    margin: "auto",
    width: "max-content",
  },

  uploadS: {
    display: "grid",
    gridTemplateColumns: "1fr",
    rowGap: "40px",
    justifyContent: "center",
  },
  uplaodSecMainImage: {
    width: "100%",
    minHeight: "450px",
    maxHeight: "450px",
    objectFit: "cover",
    borderRadius: "20px",
  },
}));
