import { TX_STATUS } from "../../components/modals/transactionModal/constants";
import { buyNft } from "../../Http/api";
import { getPolygonTxFees } from "../../utils/utils";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const fillOrder = createAsyncThunk(
  "trade/fillOrder",
  async (payload, { dispatch, getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;
    const { web3Instance } = getState().Web3Instance;
    const { setTxInfo, orderId, price } = payload;

    try {
      setTxInfo({
        txStatus: TX_STATUS.PENDING,
        txMessage: "Please accept transaction from wallet",
      });
      const priceInWei = web3Instance.utils.toWei(price.toString(), "ether");
      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const gasLimit = await marketplaceInstance.methods
            .fillOrder(orderId)
            .estimateGas({
              from: connectedWallet,
              value: priceInWei,
              maxFeePerGas,
              maxPriorityFeePerGas,
            });

          marketplaceInstance.methods
            .fillOrder(orderId)
            .send({
              from: connectedWallet,
              value: priceInWei,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })
            .once("transactionHash", function (txHash) {
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage:
                  "Please wait for transaction to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;
              const {
                from: transferFrom,
                orderId,
                tokenId,
              } = receipt.events.Traded.returnValues;
              try {
                const fd = new FormData();
                fd.append("transferFrom", transferFrom);
                fd.append("transferTo", connectedWallet);
                fd.append("amount", price);
                fd.append("orderId", orderId);
                fd.append("tokenId", tokenId);
                fd.append("transactionHash", txHash);

                const apiRes = await buyNft(fd);
                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt, apiRes });
              } catch (error) {
                reject(error);
              }
            })
            .on("error", reject);
        } catch (err) {
          reject(err);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });

      throw error;
    }
  }
);
