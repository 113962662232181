import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateProfileAPI,
  updateProfilePicAPI,
  userCoverImageUpadte,
} from "../../Http/api";
export const updateUserData = createAsyncThunk(
  "userprofileUpdate/updateProfileData",
  async (payload, { dispatch }) => {
    const { fd, setProgressStatus } = payload;
    setProgressStatus({
      loading: true,
      message: "updating please wait",
    });
    try {
      const { data } = await updateProfileAPI(fd);
      setProgressStatus({
        loading: false,
        success: true,
        message: "profile has been updated",
      });
      return data;
    } catch (error) {
      setProgressStatus({
        loading: false,
        error: true,
        message: "cannot update!",
      });
      throw error;
    }
  }
);

export const updateCoverPic = createAction(
  "userprofileUpdate/updateCoverPic",
  async (payload) => {
    const { setProgressStatus, wallet, image } = payload;
    const fd = new FormData();
    fd.append("coverPic", image);
    fd.append("walletAddress", wallet);
    setProgressStatus({
      loading: true,
      message: "updating please wait",
    });
    try {
      const { data } = await userCoverImageUpadte(fd);
      setProgressStatus({
        loading: false,
        success: true,
        message: "cover updated",
      });
      return data;
    } catch (error) {
      setProgressStatus({
        loading: false,
        error: true,
        message: "cannot update!",
      });
      throw error;
    }
  }
);

export const updateProfilePic = createAction(
  "userprofileUpdate/updateProfilePic",
  async (payload) => {
    const { setProgressStatus, wallet, image } = payload;
    const fd = new FormData();
    fd.append("profilePic", image);
    fd.append("walletAddress", wallet);
    setProgressStatus({
      loading: true,
      message: "updating please wait",
    });
    try {
      const { data } = await updateProfilePicAPI(fd);
      setProgressStatus({
        loading: false,
        success: true,
        message: "profile updated",
      });
      return data;
    } catch (error) {
      setProgressStatus({
        loading: false,
        error: true,
        message: "cannot update!",
      });
      throw error;
    }
  }
);

const resetUpdatingData = createAction("userprofileUpdate/UpdatingData");
