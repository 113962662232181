import { createSlice } from "@reduxjs/toolkit";

import { artHistory, singleNftDetails } from "./singleNftDetails.actions";
const initialState = {
  issingleNftDetailsGetting: false,
  issingleNftDetailsGettingSuccess: false,
  issingleNftDetailsGettingFailed: false,
  signleNft: {
    description: "",
    artImg: "",
    model: "",
    categoryTitle: "",
    categoryDescription: "",
    creatorName: "",
    creatorImage: "",
    creatorWallet: "",
    ownerName: "",
    ownerWallet: "",
  },
  /////////////////////

  isArtHistoryLoading: false,
  isArtHistoryLoadingFailed: false,
  isArtHistoryLoaded: false,
  artHistory: [],
};

const singleNftDetailsSlicer = createSlice({
  name: "singleNftDetails",
  initialState,
  extraReducers: {
    [singleNftDetails.pending]: (state) => {
      state.issingleNftDetailsGetting = true;
      state.issingleNftDetailsGettingSuccess = false;
      state.issingleNftDetailsGettingFailed = false;
    },
    [singleNftDetails.fulfilled]: (state, action) => {
      state.issingleNftDetailsGetting = false;
      state.issingleNftDetailsGettingSuccess = true;
      state.issingleNftDetailsGettingFailed = false;
      state.signleNft = action.payload;
    },
    [singleNftDetails.rejected]: (state) => {
      state.issingleNftDetailsGetting = false;
      state.issingleNftDetailsGettingSuccess = false;
      state.issingleNftDetailsGettingFailed = true;
    },
    //////////////////////////////
    [artHistory.pending]: (state, action) => {
      state.isArtHistoryLoading = true;
    },
    [artHistory.fulfilled]: (state, action) => {
      state.isArtHistoryLoading = false;
      state.isArtHistoryLoaded = true;
      state.artHistory = action.payload.transactionHistory;
    },
    [artHistory.failed]: (state, action) => {
      state.isArtHistoryLoading = false;
      state.isArtHistoryLoadingFailed = true;
    },
  },
});

export default singleNftDetailsSlicer.reducer;
