import { makeStyles } from "@material-ui/styles";
import coverDummy from "../../assets/profileBanner.png";
import { Box, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Input from "../../components/input";
import CustomButton from "../../components/buttons";
import ImageIcon from "@material-ui/icons/Image";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import {
  updateCoverPic,
  updateProfilePic,
  updateUserData,
} from "../../redux/updateProfile/updateProfile.action";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { basecoverUrl, baseProfileUrl } from "../../Http/config";
import profileAvatar from "../../assets/images/profileAvatar.webp";
import ProgressSnackBar from "../../components/progressSnackBar";
import { updateProfileSchema } from "./schema";
import { userProfile } from "../../redux/userProfile/userProfile.actions";

const EditProfile = () => {
  const classes = useStyles();

  const {
    userId,
    firstName,
    lastName,
    userName,
    email,
    bio,
    image,
    cover,
    walletAddress,
    createdAt,
    username,
  } = useSelector((state) => state.userProfile.user);
  const { wallet } = useParams();

  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(
    image ? baseProfileUrl + image : profileAvatar
  );
  const [coverImage, setCoverImage] = useState(
    cover ? basecoverUrl + cover : coverDummy
  );
  const [progressStatus, setProgressStatus] = useState({
    loading: false,
    success: false,
    error: false,
    message: false,
  });
  const coveRef = useRef();
  const imageRef = useRef();

  const profileCoverUpload = (e) => {
    const coverImage = e.target.files[0];
    setCoverImage(URL.createObjectURL(coverImage));

    dispatch(updateCoverPic({ setProgressStatus, wallet, image: coverImage }));
  };
  const profileImageUpload = (e) => {
    const profilePicture = e.target.files[0];

    setProfileImage(URL.createObjectURL(profilePicture));

    dispatch(
      updateProfilePic({ setProgressStatus, wallet, image: profilePicture })
    );
  };

  const initialValues = {
    userId,
    firstName,
    lastName,
    userName,
    email,
    bio,
    image,
    cover,
    walletAddress,
    createdAt,
  };
  const formik = useFormik({
    initialValues: initialValues,

    enableReinitialize: true,
    onSubmit: async (values) => {
      const fd = new FormData();
      fd.append("first_name", formik.values.firstName);
      fd.append("last_name", formik.values.lastName);
      fd.append("email", formik.values.email);
      fd.append("bio", formik.values.bio);
      fd.append("walletAddress", wallet);
      fd.append("username", formik.values.userName);

      dispatch(updateUserData({ fd, setProgressStatus }));
      dispatch(userProfile(wallet));
    },
  });
  const handleCloseProgressBar = () => {
    setProgressStatus({
      loading: false,
      success: false,
      error: false,
      message: false,
    });
  };
  return (
    <div className={classes.contianer}>
      <ProgressSnackBar
        open={progressStatus.loading}
        success={progressStatus.success}
        error={progressStatus.error}
        message={progressStatus.message}
        handleClose={handleCloseProgressBar}
      />
      <div className={classes.profileContainer}>
        <div className={classes.cover}>
          <img src={coverImage} alt="cover" className={classes.cover} />
          <div className={classes.coverBox}>
            <label className={classes.imgLable}>
              <ImageIcon className={classes.icon} />
              <input
                ref={coveRef}
                onChange={profileCoverUpload}
                type="file"
                className={classes.imgInput}
              />
            </label>
          </div>
        </div>
        <div className={classes.imgBox}>
          <img className="image" src={profileImage} alt="profile" />
          <div className={classes.uploadProfile}>
            <label className={classes.imgLable}>
              <ImageIcon className={classes.icon} />
              <input
                ref={imageRef}
                onChange={profileImageUpload}
                type="file"
                className={classes.imgInput}
              />
            </label>
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.profileInfo}>
          <Typography className={classes.title}>Ownrare</Typography>
          <Typography className={classes.address}>{walletAddress}</Typography>
          <Box className={classes.inputBoxs}>
            <div style={{ display: "flex" }}>
              <input
                placeholder="first name"
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                className={classes.inputedit}
              ></input>
              <CreateIcon style={{ color: "#fff", paddingBottom: 5 }} />
            </div>
            <div style={{ display: "flex" }}>
              <input
                placeholder="LastName"
                className={classes.inputedit}
                onChange={formik.handleChange}
                name="lastName"
                value={formik.values.lastName}
              ></input>
              <CreateIcon style={{ color: "#fff", paddingBottom: 5 }} />
            </div>
          </Box>
        </div>
        <div className={classes.bioSection}>
          <div className={classes.inner1st}>
            <Box>
              <Typography variant="h4" className={classes.textHead}>
                bio
              </Typography>

              <textarea
                className={classes.textarea}
                name="bio"
                onChange={formik.handleChange}
                value={formik.values.bio}
              ></textarea>
              <Box pt="22px">
                <Typography variant="h4" className={classes.textHead}>
                  UserName
                </Typography>
                <Input
                  value={formik.values.userName}
                  name="userName"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box pt="22px">
                <Typography variant="h4" className={classes.textHead}>
                  Twitter
                </Typography>
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.twitter}
                  name="twitter"
                />
              </Box>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 23 }}>
              <Box>
                <Typography variant="h4" className={classes.textHead}>
                  Email
                </Typography>
                <Input
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Box>
              <Box>
                <Typography variant="h4" className={classes.textHead}>
                  Facebook
                </Typography>
                <Input />
              </Box>
              <Box>
                <Typography variant="h4" className={classes.textHead}>
                  Instagram
                </Typography>
                <Input />
              </Box>

              <Box>
                <Typography variant="h4" className={classes.textHead}>
                  Linkdin
                </Typography>
                <Input />
              </Box>
            </Box>
          </div>
        </div>
        <Box className={classes.endSection}>
          <CustomButton variant="contained" type="submit">
            {" "}
            Update profile{" "}
          </CustomButton>
        </Box>
      </form>
    </div>
  );
};

export default EditProfile;

const useStyles = makeStyles((theme) => ({
  cover: {
    width: "100%",
    height: "60vh",
    borderTop: "2px solid #FFFFFF",
    borderBottom: "2px solid #FFFFFF",
    display: "flex",
  },
  contianer: {
    paddingBottom: 120,
    backgroundColor: theme.palette.background.primary,
  },
  profileContainer: {
    position: "relative",
  },
  coverBox: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    opacity: 0,
    transition: "all 0.2s ease-in",
    "&:hover": {
      opacity: 1,
    },
  },
  imgBox: {
    width: 250,
    height: 250,
    borderRadius: "50%",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    border: "2px solid #FFFFFF",

    "& .image": {
      width: 250,
      height: 250,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  uploadProfile: {
    position: "inherit",
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    width: "250px",
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.2s ease-in",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },
  icon: {
    fontSize: 82,
    color: "white",
    cursor: "pointer",
  },
  imgInput: {
    display: "none",
  },
  profileInfo: {
    padding: "180px 0 100px 0",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "180px 28px",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "36px",
    fontWeight: 800,
  },
  address: {
    color: theme.palette.text.primary,
    fontSize: "22px",
    marginTop: 18,
    wordWrap: "break-word",
  },
  typo: {
    color: theme.palette.text.primary,
    fontSize: "17px",
    marginTop: 15,
  },

  inputedit: {
    backgroundColor: theme.palette.background.primary,
    border: "none",
    color: theme.palette.text.primary,
    borderBottom: "1px solid" + theme.palette.background.blue,

    "&:focus": {
      outline: "none !important",
    },
  },
  bioSection: {
    width: "90%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
  },

  inner1st: {
    display: "grid",
    gap: 50,

    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr ",
    },
  },
  textHead: {
    textTransform: "uppercase",
    paddingLeft: 10,
    paddingBottom: 10,
    color: theme.palette.text.primary,
  },
  textarea: {
    width: "100%",
    height: 120,
    borderRadius: 10,
    backgroundColor: theme.palette.background.primary,
    border: "2px solid" + theme.palette.text.primary,
    color: theme.palette.text.primary,
    outline: "none",
    boxSizing: "border-box",
    padding: "5px 10px",
  },
  innersend: {
    display: "grid",

    padding: "50px 0px",
    gap: 50,

    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr ",
    },
  },
  btn: {
    width: "100%",
    marginTop: 25,
  },
  endSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0px",
  },
  inputBoxs: {
    display: "flex",
    gap: 20,
    marginTop: 20,
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
      alignItems: "center",
      gap: 50,
      flexDirection: "column",
    },
  },
}));
