import {
  Box,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "../Dialog";

import CancelIcon from "@material-ui/icons/Cancel";

import CardPopUp from "../popupCard";
import CustomButton from "../buttons";
import { burnBeforeListing } from "../../redux/burnNft/burnNft.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TransactionModal from "../modals/transactionModal";
import { useParams } from "react-router-dom";
import { getUserInWallet } from "../../redux/userProfile/userProfile.actions";
import { clearBurnNft } from "../../redux/burnNft/burnNft.slice";
import { useIsUserAuthenticated } from "../../utils/utils";

export default function BurnBeforeLIsting({
  showModal,
  setShowModal,
  details,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setShowModal({
      ...showModal,
      burnBeforeListing: false,
    });
  };

  return (
    <Dialog open={showModal.burnBeforeListing} className={classes.loginMain}>
      <ModalContent handleClose={handleClose} details={details} />
    </Dialog>
  );
}

const ModalContent = ({ handleClose, details }) => {
  const { tokenId } = details;

  console.log(details, "details");
  const isUserAuthenticated = useIsUserAuthenticated();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isburnBeforeListingComplete } = useSelector((state) => state.burnNft);
  const { wallet } = useParams();

  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });
  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
    dispatch(clearBurnNft());
    dispatch(getUserInWallet(wallet));
  };
  const onTxModalExited = () => {};
  const handleBurnNft = () => {
    const status = isUserAuthenticated();
    status && dispatch(burnBeforeListing({ tokenId, setTxInfo }));
  };

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <TransactionModal
          txInfo={txInfo}
          onClose={onTxModalClose}
          onExited={onTxModalExited}
        />
        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleClose}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <Box className={classes.loginGrid}>
          <Box className={classes.imageContainer}>
            <Typography className="typoo" variant="h2">
              burn-before-listing
            </Typography>
            <Typography className="typoo1" variant="h2">
              NFT
            </Typography>
          </Box>

          <Box className={classes.endContainer}>
            <Box>
              <CardPopUp details={details} />
            </Box>
            <Box className={classes.secondSection}>
              <Typography variant="h1" className="typoo1">
                tERMS AND cONDITIONS
              </Typography>
              <Typography variant="body1" className="typoo2">
                are you sure you want to burn nFT? this will completely remove
                the NFT from the blockchain and never be restored.
              </Typography>

              <div className={classes.checkSec}>
                <Checkbox
                  className="checkbox"
                  inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                />
                <Box className={classes.checktext}>
                  <Typography variant="body1">i agree to the </Typography>
                  <Typography variant="body1" className="text">
                    TERMS AND CONDITIONS
                  </Typography>
                </Box>
              </div>
              <Box className={classes.buttonSec}>
                <CustomButton
                  variant="outlined"
                  className="button"
                  onClick={handleBurnNft}
                >
                  {" "}
                  burn NFT
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },
  loginMain: {
    "& .MuiPaper-root": {
      marginTop: 80,
      width: "100%",

      maxWidth: "60%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100% - 100px)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },

      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },

  imageContainer: {
    textTransform: "capitalize",
    display: "flex",
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 60,

    " & .typoo1": {
      color: theme.palette.background.blue,
    },
  },

  innerText: {
    paddingTop: 10,
    display: "flex",
    maxWidth: 450,
    margin: "auto",
    justifyItems: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .typoo": {
      fontWeight: 300,
      fontSize: 18,
      textAlign: "center",

      // color: theme.palette.text.primary,
    },
  },

  endContainer: {
    padding: "20px 40px",
    marginTop: 20,
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    placeContent: "center",
    justifyContent: "center",
    gap: 15,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr ",
    },

    "& .innerCont": {
      width: 300,
      height: 60,

      borderRadius: 50,
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
  },

  secondSection: {
    paddingTop: 60,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 30,
    },

    "& .typoo1": {
      textTransform: "upperCase",
      fontSize: 27,
      color: theme.palette.background.blue,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .typoo2": {
      width: 420,
      fontSize: 24,
      paddingTop: 5,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        fontSize: 16,
      },
    },
  },

  checkSec: {
    paddingTop: 20,
    display: "flex",

    "& .MuiCheckbox-root": { color: theme.palette.text.primary },
  },

  checktext: {
    textTransform: "capitalize",
    paddingTop: 10,
    display: "flex",
    wordSpacing: 4,
    gap: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      wordSpacing: 1,
    },

    "& .text": {
      color: theme.palette.background.blue,
      textTransform: "capital",
    },
  },

  buttonSec: {
    display: "flex",
    justifyContent: "end",
    " & .button": {
      width: 150,
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.blue,
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
      },
    },
  },
}));
