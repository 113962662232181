import { TextField } from "@material-ui/core";

import { useStyles } from "./styles";
const RegisterInput = (props) => {
  const classes = useStyles();

  return (
    <>
      <TextField {...props} className={classes.input} />
    </>
  );
};

export default RegisterInput;
