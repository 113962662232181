import { makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/buttons";
import Card, { NftCardSkelton } from "../../components/card";

import SearchBar from "../../components/searchBar";
import MarketDropSec from "../../components/sortByDropDown";
import { sortNftsByOption } from "../../helper";
import { getAllArts } from "../../redux/arts/arts.actions";
import { resetStateOnSearch } from "../../redux/arts/arts.slicer";

export const sortOptions = {
  label: "Sort By",
  options: [
    "Recently Listed",
    "Price: Low to high",
    "Price: high to low",
    "Oldest Listings",
  ],
};

const Market = () => {
  const [sortOption, setSortOption] = useState("");
  const [search, setSearch] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    marketArts: { nfts, totalPages, currentPage },
    isgetAllArtsGetting,

    isgetAllArtsGettingSuccess,
  } = useSelector((state) => state.AllArts);
  useEffect(() => {
      dispatch(getAllArts({ search }));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearchMarket = (e) => {
    dispatch(resetStateOnSearch());
    setSearch(e.target.value);

    dispatch(getAllArts({ search: e.target.value }));
  };
  const handleLoadMore = () => {
    let pageNo = currentPage;
    dispatch(getAllArts({ search, pageno: pageNo + 1 }));
  };

  return (
    <div className={classes.container}>
      <div style={{ padding: "40px 0px" }}>
        <Typography className={classes.title} align="center">
          Market
        </Typography>
      </div>
      <div className={classes.marketSearch}>
        <SearchBar
          className={classes.SearchBar}
          onChange={handleSearchMarket}
        />
        <MarketDropSec
          item={sortOptions}
          selectedOption={sortOption}
          handleSelectOptionChange={setSortOption}
          className={classes.sort}
        />
      </div>

      <div className={classes.cardBox}>
        {nfts &&
          sortNftsByOption(nfts, sortOption).map((item) => {
            return <Card viewBtn={false} item={item} />;
          })}

        {isgetAllArtsGetting &&
          Array(8)
            .fill(1)
            ?.map((item, i) => <NftCardSkelton key={i} />)}
      </div>
      {isgetAllArtsGettingSuccess && nfts.length === 0 && (
        <Typography variant="h1" align="center" style={{ color: "white" }}>
          No Arts To Show!
        </Typography>
      )}
      <div className={classes.loadMoreBtnSec}>
        {currentPage < totalPages && (
          <CustomButton
            children="load more"
            variant="contained"
            className={classes.btn}
            onClick={handleLoadMore}
          />
        )}
      </div>
    </div>
  );
};

export default Market;
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.primary,
  },
  marketSearch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 28,
    gap: 28,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  SearchBar: {
    flexGrow: 3,
    width: "200%",
  },
  sort: {
    flexGrow: 1,
  },

  loadMoreBtnSec: {
    padding: "40px 0px",
    display: "flex",
    justifyContent: "Center",
  },

  cardBox: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(290px, 1fr))",
    justifyContent: "space-around",
    columnGap: "20px",
    rowGap: "100px",

    [theme.breakpoints.down("xs")]: {
      placeItems: "flex-end",

      rowGap: "80px",
    },

    [theme.breakpoints.down("xs")]: {
      placeItems: "center",
      gridTemplateColumns: "1fr",
      rowGap: "80px",
    },
    padding: "10px 30px",
  },

  title: {
    fontSize: "38px",
    color: theme.palette.text.primary,
    fontWeight: 800,
  },
}));
