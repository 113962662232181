import { chainIdToNetworkInfo } from "../web3/constants";

export const switchNetwork = async (provider, chainId = "0x1") => {
  if (!provider) return;
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
  } catch (err) {
    // if no chain found request to add
    if (err.code === 4902 || /Unrecognized chain ID/.test(err.message || err))
      return await provider.request({
        method: "wallet_addEthereumChain",
        params: [chainIdToNetworkInfo[chainId].configs],
      });
  }
};
