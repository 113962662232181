import * as yup from "yup";

export const nftSchema = yup.object({
  title: yup.string("").required("NFT title is required"),
  description: yup.string("").required("Description is required"),
  file: yup
    .mixed("")
    .test(
      "image",
      "Please select a valid image or video.",
      (file) =>
        file &&
        /\.(jpg|jpeg|png|gif|tiff|tif|heif|heic|svg|svgz|ai|mp4|ogg|webm|mov)$/.test(
          file.name.toLowerCase()
        )
    )
    .required("Image is required"),

  royaltiesPercentage: yup.string(""),
  agree: yup.boolean().oneOf([true], "you should agree to terms and service"),
});
