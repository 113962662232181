import { Button, InputBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CopyrightIcon from "@material-ui/icons/Copyright";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/logo.svg";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Divider from "../divider";
const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div>
        <Divider />
      </div>
      <div className={classes.container}>
        <div>
          <div className={classes.searchBox}>
            <InputBase className={classes.input} placeholder="signUp" />
            <Button className={classes.btn}>Sign UP</Button>
          </div>
        </div>
        <div className={classes.column}>
          <img src={logo} alt="logo" />
          <Link to="/" className={classes.links}>
            About us
          </Link>
          <Link to="/" className={classes.links}>
            How it works
          </Link>
          <Link to="/" className={classes.links}>
            Support
          </Link>
        </div>
        <div className={classes.column}>
          <Typography className={classes.title}>Useful links</Typography>
          <Link to="/" className={classes.links}>
            terms of service
          </Link>
          <Link to="/" className={classes.links}>
            Privacy Policy
          </Link>
          <Link to="/" className={classes.links}>
            Return and Refund Policy
          </Link>
          <Link to="/faqs" className={classes.links}>
            FAQ
          </Link>
        </div>
        <div className={classes.column}>
          <Typography className={classes.title}>contact</Typography>
          <Link to="/" className={classes.links}>
            contact@test.io
          </Link>
          <Link to="/" className={classes.links}>
            xyz, block / street
          </Link>
          <Link to="/" className={classes.links}>
            area xyz
          </Link>
          <Link to="/" className={classes.links}>
            city xyz
          </Link>
          <div className={classes.socialBox}>
            <Typography className={classes.subTitle}>
              join the community
            </Typography>
            <div className={classes.socialMedia}>
              <Link to="/" className={classes.links}>
                <FacebookIcon className={classes.icons} />
              </Link>
              <Link to="/" className={classes.links}>
                <InstagramIcon className={classes.icons} />
              </Link>
              <Link to="/" className={classes.links}>
                <TwitterIcon className={classes.icons} />
              </Link>
              <Link to="/" className={classes.links}>
                <LinkedInIcon className={classes.icons} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.end}>
        <CopyrightIcon />
        <Typography className={classes.icon}> Copyright 2022</Typography>
      </div>
      <Divider />
    </div>
  );
};

export default Footer;
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.background.primary,
  },
  container: {
    backgroundColor: theme.palette.background.primary,
    display: "flex",
    padding: "28px",
    justifyContent: "space-between",
    gap: "28px",
    flexWrap: "wrap",
  },
  divider: {
    width: "390px  ",
  },
  searchBox: {
    background: "white",
    borderRadius: "12px",
  },
  btn: {
    borderRadius: "12px",
    backgroundColor: theme.palette.background.blue,
    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
  },
  input: {
    color: "black",
    padding: "0 10px",
    maxWidth: "188px",
  },
  column: {
    display: " flex",
    flexDirection: "column",
    gap: "13px",
  },
  links: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  subTitle: {
    marginTop: "20px",
    color: theme.palette.text.primary,
    textDecoration: "none",
    fontWeight: 600,
    fontSize: "20px",
    textTransform: "capitalize",
  },
  title: {
    color: theme.palette.text.primary,
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: "26px",
  },
  socialMedia: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
  },
  icons: {
    color: theme.palette.text.primary,
    marginTop: "10px",
    fontSize: "33px",
  },

  end: {
    padding: "20px 0px",
    display: "flex",
    color: theme.palette.text.primary,
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    marginTop: 3,
  },
}));
