import {
  Box,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Dialog from "../Dialog";

import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../buttons";
import { connectWallet } from "../../redux/wallet/wallet.actions";
import { useEffect } from "react";
import { toogleConnecWallet } from "../../redux/login/login.slicer";
import CancelIcon from "@material-ui/icons/Cancel";
export default function ConnectWallet() {
  const classes = useStyles();

  const { showConnectWalletModal } = useSelector((state) => state.LoginSlice);

  return (
    <Dialog open={showConnectWalletModal} className={classes.loginMain}>
      <ModalContent />
    </Dialog>
  );
}

const ModalContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isUserLogginInSuccess } = useSelector((state) => state.auth);
  const handleConnectWallet = () => {
    dispatch(connectWallet());
  };
  const handleCloseModal = () => {
    dispatch(toogleConnecWallet(false));
  };

  useEffect(() => {
    isUserLogginInSuccess && dispatch(toogleConnecWallet(false));
  }, [isUserLogginInSuccess, dispatch]);

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <div className={classes.cancelIconContainer}>
          <IconButton onClick={handleCloseModal}>
            <CancelIcon
              className="close-icon"
              color="secondary"
              fontSize="large"
            />
          </IconButton>
        </div>
        <div className={classes.wrongWalletLower}>
          <Typography>It seems that your wallet is not connected!</Typography>

          <CustomButton
            children="Connect Wallet"
            variant="contained"
            onClick={handleConnectWallet}
          />
        </div>
      </DialogContent>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },

  wrongWalletLower: {
    paddingTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    placeItems: "center",
    rowGap: "20px",
    padding: "30px 20px",
  },
}));
