import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import profle from "../../assets/profile.png";
import { getIpfsUrl, whatIsFileType } from "../../helper";
import { baseProfileUrl } from "../../Http/config";
import { revertOrder } from "../../redux/nftOrder/nftOrder.actions";
import { getUserInWallet } from "../../redux/userProfile/userProfile.actions";
import ApprovalModal from "../modals/approvalModal";
import TransactionModal from "../modals/transactionModal";
import CardModals from "./CardModals";

import ShowUserActionBtns from "./showUserActionBtns";
const Card = ({ item, isUser, isCollection = false, isOrders = false }) => {
  const classes = useStyles();

  const {
    artName,
    artPrice,
    description,
    artImg,
    ownerName,
    deliveryId,
    ownerWallet,
    ownerImage,
    isOrderPlaced,
    orderDeliveryId,
    tokenId,
  } = item;

  const url = getIpfsUrl(artImg);
  const dispatch = useDispatch();
  const { wallet } = useParams();

  const [showModal, setShowModal] = useState({
    burnNftModal: false,
    viewNftModal: false,
    orderNftModal: false,
    reSellNftModal: false,
    listingModal: false,
  });
  const handleOpenModal = (e) => {
    e.preventDefault();

    setShowModal({ ...showModal, [e.currentTarget.dataset.buttonKey]: true });
  };
  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });
  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
  };
  const RevertOrder = (e) => {
    e.preventDefault();
    dispatch(revertOrder({ orderDeliveryId, tokenId, setTxInfo }));
  };
  const onTxModalExited = () => {
    dispatch(getUserInWallet(wallet));
  };

  return (
    <div>
      <Link to={`/nft-detail/${tokenId}`}>
        <div className={classes.container}>
          <Link
            to={"/userProfile/" + ownerWallet}
            className={classes.ProfileLnks}
          >
            <img
              className={classes.profilePic}
              src={ownerImage ? baseProfileUrl + ownerImage : profle}
              alt="userProfile"
            />
            <Typography variant="h5" className={classes.ownerName}>
              @{ownerName}
            </Typography>
          </Link>
          <div>
            {/* <Link to={`/nft-detail/${tokenId}`} className={classes.links}> */}
            {whatIsFileType(artImg) === "image" && (
              <img src={url} alt="displayed nft" className={classes.nftImage} />
            )}
            {whatIsFileType(artImg) === "video" && (
              <video
                src={url}
                loop
                mute
                autoPlay
                playsInline
                controls
                className={classes.nftImage}
              />
            )}
          </div>
          <div className={classes.divider}></div>
          <div className={classes.nftBadgeBox}>
            <Typography className={classes.nftBadge}>{artName}</Typography>
          </div>
          <div>
            <Typography variant="h4" className={classes.title}>
              {description}
            </Typography>

            <div className={classes.priceBox}>
              <Typography className={classes.subTitle}>price</Typography>
              <Typography className={classes.subTitle}>
                {artPrice ? "MATIC" + artPrice : "Not Listed Yet"}
              </Typography>
            </div>
            <TransactionModal
              txInfo={txInfo}
              onClose={onTxModalClose}
              onExited={onTxModalExited}
            />
            <ShowUserActionBtns
              isUser={isUser}
              handleOpenModal={handleOpenModal}
              isCollection={isCollection}
              isOrders={isOrders}
              artPrice={artPrice}
              isOrderPlaced={isOrderPlaced}
              RevertOrder={RevertOrder}
            />
          </div>
        </div>
      </Link>
      <CardModals
        item={item}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  );
};

export default Card;

export const NftCardSkelton = () => {
  const classes = useStyles();
  return (
    <div className={classes.cardSkeltonMain}>
      <Skeleton
        variant="circle"
        className={classes.profilePic}
        animation="wave"
        width={"46px"}
        height={"46px"}
      />

      <Skeleton
        variant="rect"
        animation="wave"
        height={"220px"}
        className={classes.nftImage}
      />

      <div className={classes.divider}></div>
      <div className={classes.nftBadgeBox}>
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.nftBadge}
          width={"80px"}
        />
      </div>
      <div className={classes.priceBox}>
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.subTitle}
          width={"40px"}
        />
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.subTitle}
        />
      </div>
      <div className={classes.priceBox}>
        <Skeleton variant="rect" animation="wave" width={"80px"} />
        <Skeleton variant="rect" animation="wave" width={"80px"} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.secondary,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    flexShrink: 1,
    maxWidth: "300px",
    padding: "10px 10px",
    boxShadow: " 0px 4px 15px 5px rgba(0, 0, 0, 0.25)",
  },
  cardSkeltonMain: {
    backgroundColor: theme.palette.background.secondary,
    display: "grid",
    maxWidth: "300px",
    gridTemplateColumns: "1fr",
    rowGap: "10px",
    padding: "10px 10px",
  },
  profilePic: {
    width: "46px",
    height: "46px",
    borderRadius: "50%",
  },
  divider: {
    backgroundColor: theme.palette.background.primary,
    height: "5px",
    margin: "10px 0",
  },
  ProfileLnks: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0px",
  },
  links: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nftImage: {
    width: "100%",
    minHeight: "220px",
    maxHeight: "220px",
    display: "block",
    objectFit: "cover",
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "20px",
    margin: "10px 0",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: "20px",
  },

  nftBadgeBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  nftBadge: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.blue,
    padding: "3px 15px",
  },
  ownerName: {
    color: "white",
    textTransform: "capitalize",
    textDecoration: "none",
    fontStyle: "italic",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100ch",
    textAlign: "end",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  priceBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },
}));
