import { Typography } from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import profile from "../../assets/profile.png";
import { getIpfsUrl } from "../../helper";
import { fillOrder } from "../../redux/trade/trade.actions";
import CustomButton from "../buttons";

import TransactionModal from "../modals/transactionModal";

import { useIsUserAuthenticated } from "../../utils/utils";
import { resetTradeState } from "../../redux/trade/trade.slicer";
import ImageRender from "../ImageRenderer";
const NftDetails = () => {
  const classes = useStyles();

  const {
    signleNft: {
      description,
      artImg,
      model,
      creatorName,
      ownerName,
      ownerWallet,
      artName,
      itemBrand,
      artPrice,
      orderId,

      nftImages,
    },
  } = useSelector((state) => state.singleNftDetails);
  const { walletAddress } = useSelector((state) => state.auth.user);
  const { isOrderFilled } = useSelector((state) => state.Trade);
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  const isUserAuthenticated = useIsUserAuthenticated();

  const url = getIpfsUrl(artImg);

  const [txInfo, setTxInfo] = useState({
    txStatus: null,
    txMessage: null,
    txHash: null,
  });
  const onTxModalClose = () => {
    setTxInfo({
      txStatus: null,
      txMessage: null,
      txHash: null,
    });
  };
  const onTxModalExited = () => {
    if (isOrderFilled) {
      dispatch(resetTradeState());
      naviagte("/userprofile/" + walletAddress);
    }
    dispatch(resetTradeState());
  };
  const handleBuyNft = async () => {
    const status = isUserAuthenticated();

    if (status) dispatch(fillOrder({ price: artPrice, setTxInfo, orderId }));
  };

  return (
    <div className={classes.container}>
      <TransactionModal
        txInfo={txInfo}
        onClose={onTxModalClose}
        onExited={onTxModalExited}
      />

      <div className={classes.nftBox}>
        <ImageRender
          url={url}
          artImg={artImg}
          ownerWallet={ownerWallet}
          relImage={nftImages}
        />
      </div>
      <div className={classes.detailsBox}>
        <Typography className={classes.title}>{artName}</Typography>
        <Typography className={classes.tag}>{description}</Typography>
        <Typography className={classes.tag}>
          {itemBrand}, {model}
        </Typography>
        <div className={classes.creater}>
          <div>
            <img src={profile} alt="profile" className={classes.profile} />
          </div>
          <div>
            <Typography className={classes.creatorHead}>owned by</Typography>

            <Link
              to={`/userprofile/${ownerWallet}`}
              className={classes.creatorName}
            >
              {ownerName}
            </Link>
          </div>
        </div>
        <div className={classes.creater}>
          <div>
            <img src={profile} alt="profile" className={classes.profile} />
          </div>
          <div>
            <Typography className={classes.creatorHead}>created by</Typography>

            <Link
              to={`/userprofile/${creatorName}`}
              className={classes.creatorName}
            >
              {" "}
              {creatorName}
            </Link>
          </div>
        </div>
        <Typography className={classes.price}>MATIC {artPrice}</Typography>
        {artPrice && ownerWallet !== walletAddress && (
          <CustomButton
            variant="contained"
            className={classes.btn}
            onClick={handleBuyNft}
          >
            BUY NOW
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export const SignleNftSkelton = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.nftBox}>
        <Skeleton
          nftImage={classes.nft}
          animation="wave"
          variant="rect"
          height="400px"
          width="500px"
        />
      </div>
      <div className={classes.detailsBox}>
        <Skeleton
          variant="text"
          animation="wave"
          className={classes.tag}
          width="300px"
        />
        <Skeleton variant="text" animation="wave" className={classes.title} />
        <Skeleton variant="text" animation="wave" className={classes.tag} />
        <div>
          <Skeleton
            variant="circle"
            animation="wave"
            className={classes.profile}
            width="60px"
            height="60px"
          />
        </div>
        <div>
          <Skeleton
            variant="text"
            animation="wave"
            className={classes.creatorHead}
            width="100px"
          />
          <Skeleton
            variant="text"
            animation="wave"
            className={classes.creatorName}
          />
        </div>
        <div>
          <Skeleton
            variant="circle"
            animation="wave"
            className={classes.profile}
            width="60px"
            height="60px"
          />
        </div>
        <div>
          <Skeleton
            variant="text"
            animation="wave"
            className={classes.creatorHead}
            width="100px"
          />
          <Skeleton
            variant="text"
            animation="wave"
            className={classes.creatorName}
          />
        </div>
      </div>

      <Skeleton
        variant="text"
        animation="wave"
        className={classes.price}
        width="300px"
      />
      <Skeleton
        variant="rect"
        animation="wave"
        className={classes.btn}
        width="100px"
        height="50px"
      />
    </div>
  );
};

export default NftDetails;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyItems: "center",
    padding: "28px",
    gap: "25px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  nft: {
    maxWidth: "455.35px",
    maxHeight: "400px",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    objectFit: "cover",

    backgroundColor: theme.palette.background.secondary,

    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  detailsBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // gap: 15,
  },
  tag: {
    color: theme.palette.text.blue,
    fontSize: "20px",
    textTransform: "capitalize",
  },
  title: {
    fontWeight: 700,
    fontSize: "48.1119px",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "20ch",
  },
  creater: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    margin: "10px 0px",
  },
  creatorHead: {
    fontWeight: 400,
    fontSize: "14.605px",
    color: theme.palette.text.primary,
  },
  creatorName: {
    fontWeight: 700,
    fontSize: "14.605px",
    color: theme.palette.text.primary,
    fontStyle: "italic",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  profile: {
    width: "72.21px",
  },
  price: {
    color: theme.palette.text.primary,
    fontSize: "28.6667px",
    marginTop: 20,
  },
  btn: {
    padding: "8px",
    borderRadius: "10px",
  },

  nftBox: {
    height: "100%",
  },
}));
