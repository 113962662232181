import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { requestGetFeaturedArts } from "../../Http/api";
export const getFeatureArts = createAsyncThunk(
  "arts/getFeatureArts",
  async (payload) => {
    try {
      const { data } = await requestGetFeaturedArts(payload);

      return data;
    } catch (error) {
      throw error;
    }
  }
);
