import "./App.css";

import Routers from "./routes/routes";
import UniversalHooks from "./UniversalHooks";

function App() {
  return (
    <>
      <UniversalHooks />
      <Routers />;
    </>
  );
}

export default App;
