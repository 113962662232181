import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useEffect } from "react";
import { useState } from "react";
import MetamaskIcon from "../../assets/fox.png";

const MetamaskInstall = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.container}>
        <Box display={"flex"} alignItems="center" justifyContnt={"center"}>
          <img src={MetamaskIcon} alt="metamask" />
        </Box>
      </DialogTitle>
      <ModalContent />{" "}
      <Box position={"relative"} className={classes.closeIcon}>
        <Box>
          <IconButton size="small" onClick={onClose}>
            <CancelIcon color="secondary" />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MetamaskInstall;
const ModalContent = () => {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.mainContainer}>
        <div className={classes.wrongWalletLower}>
          <Typography variant="h4">
            It seems that you don't have Metamask on your browser
          </Typography>
          <div>
            <a
              href="https://metamask.io/download/"
              target={"_blank"}
              className={classes.button}
              rel="noreferrer"
            >
              Install Metamask
            </a>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.primary,
  },

  cancelIconContainer: {
    position: "absolute",
    right: "5px",
    top: "5px",

    "& .close-icon": {
      fontSize: "1.5rem",
    },
  },
  container: { backgroundColor: theme.palette.background.primary },
  wrongWalletLower: {
    paddingTop: "20px",
    display: "grid",
    gridTemplateColumns: "1fr",
    placeItems: "center",
    rowGap: "20px",
    padding: "30px 20px",
  },
  closeIcon: {
    position: "absolute",
    top: 2,
    right: 2,
  },
  button: {
    border: `2px solid ${theme.palette.background.blue}`,
    borderRadius: "3px",
    backgroundColor: theme.palette.background.blue,
    color: "white",
    padding: "5px 8.5px",
    fontWeight: 600,
    fontSize: "15px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
  },
}));
