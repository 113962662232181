import { createSlice } from "@reduxjs/toolkit";
import { getAllArts } from "./arts.actions";
const initialState = {
    isgetAllArtsGetting: false,
    isgetAllArtsGettingSuccess: false,
    isgetAllArtsGettingFailed: false,
    marketArts: { nfts: [], totalPages: null, currentPage: null },
};

const handleFulfillment = (state, action) => {
    const { payload } = action;
    let dataArray = action.payload.nfts;
    if (payload.currentPage !== 1)
        dataArray = [...state.marketArts.nfts, ...dataArray];
    state.marketArts.nfts = dataArray;
    state.marketArts.totalPages = action.payload.totalPages;
    state.marketArts.currentPage = action.payload.currentPage;
};

const getAllArtsSlicer = createSlice({
    name: "getAllArts",
    initialState,
    extraReducers: {
        [getAllArts.pending]: (state) => {
            state.isgetAllArtsGetting = true;
            state.isgetAllArtsGettingSuccess = false;
            state.isgetAllArtsGettingFailed = false;
        },
        [getAllArts.fulfilled]: (state, action) => {
            state.isgetAllArtsGetting = false;
            state.isgetAllArtsGettingSuccess = true;
            state.isgetAllArtsGettingFailed = false;
            handleFulfillment(state, action);
        },
        [getAllArts.rejected]: (state) => {
            state.isgetAllArtsGetting = false;
            state.isgetAllArtsGettingSuccess = false;
            state.isgetAllArtsGettingFailed = true;
        },
    },
    reducers: {
        resetStateOnSearch: (state) => {
            state.isgetAllArtsGetting = true;
            state.isgetAllArtsGettingSuccess = false;
            state.isgetAllArtsGettingFailed = false;
            state.marketArts = { nfts: [], totalPages: null, currentPage: null };
        },
    },
});
export const { resetStateOnSearch } = getAllArtsSlicer.actions;
export default getAllArtsSlicer.reducer;