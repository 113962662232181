import WrongChain from "./components/modals/wrongChain";
import ConnectingWallet from "./components/modals/connectingWallet";
import MetamaskInstall from "./components/metamaskInstallModal";
import ConnectWallet from "./components/modals/connectWallet";

const UniversalDialogs = () => {
  return (
    <>
      <WrongChain />
      <ConnectingWallet />
      <MetamaskInstall />
      <ConnectWallet />
    </>
  );
};

export default UniversalDialogs;
