import axios from "axios";
import ImgixClient from "@imgix/js-core";
const baseURL = `http://5.189.150.17:8000/api`;
// const baseURL = `http://localhost:8000/api`;
const baseUrlImage = "http://5.189.150.17:8000/";
export const baseUrlRelImages = baseUrlImage + "/images/";

export const baseProfileUrl = baseUrlImage + "/images/users/";
export const basecoverUrl = baseUrlImage + "/images/users/cover/";

export const OwnRareURL = axios.create({ baseURL });
export const imgixClient = new ImgixClient({
    domain: "boax-assets.imgix.net",
    secureURLToken: process.env.REACT_APP_IMGIX_KEY,
});