import { useDispatch, useSelector } from "react-redux";
import { getContractInstances } from "../redux/contract/constatns";
import {
  connectWallet,
  disconnectWallet,
} from "../redux/wallet/wallet.actions";
import { chainIdToNetworkInfo } from "../redux/web3/constants";

// helper funcion with web3
export const useWeb3Functions = () => {
  const dispatch = useDispatch();
  const { Web3 } = useSelector((state) => state.Web3Instance);

  const onWalletConnect = () => dispatch(connectWallet());

  const onWalletDisconnect = () => dispatch(disconnectWallet());

  /**
   * @dev it will return and object with following properties: 
          erc721Instance, auctionInstance, marketplaceInstance 
   * @param chainId is the hex string of the chain
  */
  const getContractInstancesWithRPC = (chainId = "0x1") => {
    const rpcWeb3Instance = new Web3(chainIdToNetworkInfo[chainId].rpcUrl);

    const {
      contracts: { erc721Address, auctionAddress, marketplaceAddress },
    } = chainIdToNetworkInfo[chainId];

    return getContractInstances({
      web3Instance: rpcWeb3Instance,
      auctionAddress,
      erc721Address,
      marketplaceAddress,
    });
  };

  return {
    getContractInstancesWithRPC,
    onWalletConnect,
    onWalletDisconnect,
  };
};
