import { createSlice } from "@reduxjs/toolkit";
import { initializeContracts } from "./contract.actions";

const initialState = {
  isContractInitialized: false,
  erc721Instance: null,
  auctionInstance: null,
  marketplaceInstance: null,
};

const contractSlicer = createSlice({
  name: "contract-slicer",
  initialState,
  extraReducers: {
    [initializeContracts.fulfilled]: (state, action) => {
      state.isContractInitialized = true;
      state.erc721Instance = action.payload.erc721Instance;
      state.auctionInstance = action.payload.auctionInstance;
      state.marketplaceInstance = action.payload.marketplaceInstance;
    },
  },
});

export default contractSlicer.reducer;
