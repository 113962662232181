import erc721ABI from "../../assets/abis/OwnRareNFT.json";

import marketplaceABI from "../../assets/abis/OwnRareMarketplace.json";

/**
 * @dev it will return and object with following properties:
 * erc721Instance, auctionInstance, marketplaceInstance
 */
export const getContractInstances = ({
  web3Instance,
  erc721Address,
  marketplaceAddress,
}) => {
  const erc721Instance = new web3Instance.eth.Contract(
    erc721ABI,
    erc721Address
  );

  const marketplaceInstance = new web3Instance.eth.Contract(
    marketplaceABI,
    marketplaceAddress
  );

  return { erc721Instance, marketplaceInstance };
};
