import { Typography } from "@material-ui/core";
import { ArtTrack } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import nft from "../../assets/watchNft.png";
import { getIpfsUrl, whatIsFileType } from "../../helper";

const CardPopUp = ({ details }) => {
  const classes = useStyles();

  const {
    artName,
    artPrice,
    description,
    artImg,
    ownerName,
    tokenId,
    ownerWallet,
    username,
    ownerImage,
    bio,
    isOwnerAdmin,
  } = details;

  const url = getIpfsUrl(artImg);

  return (
    <div className={classes.container}>
      <div style={{ width: "100%" }}>
        {/* <Link to={`/nft-detail/${tokenId}`} className={classes.links}> */}
        {whatIsFileType(artImg) === "image" && (
          <img src={url} alt="displayed nft" className={classes.nftImage} />
        )}
        {whatIsFileType(artImg) === "video" && (
          <video
            src={url}
            loop
            mute
            autoPlay
            playsInline
            controls
            className={classes.nftImage}
          />
        )}
        <div className={classes.divider}></div>
        <div className={classes.nftBadgeBox}>
          <Typography className={classes.nftBadge}>{artName}</Typography>
        </div>
        <div>
          <Typography variant="h4" className={classes.title}>
            {description}
          </Typography>
          {artPrice && (
            <div className={classes.priceBox}>
              <Typography className={classes.subTitle}>price</Typography>
              <Typography className={classes.subTitle}>
                MATIC {artPrice}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardPopUp;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.secondary,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexShrink: 1,
    maxWidth: "300px",
    minWidth: "300px",
    padding: "30px 10px",
    boxShadow: " 0px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("xs")]: {
      width: 200,
      height: 280,
    },
  },

  divider: {
    backgroundColor: theme.palette.background.primary,
    height: "5px",
    margin: "10px 0",
  },
  nftImage: {
    width: "100%",

    minHeight: "220px",
    maxHeight: "220px",
    display: "block",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 140,
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "16px",
    margin: "10px 0",
  },
  subTitle: {
    color: theme.palette.text.secondary,
    fontSize: "20px",
  },
  priceBox: {
    display: "flex",
    justifyContent: "space-between",
    PaddingBottom: 10,
  },
  nftBadgeBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  nftBadge: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.blue,
    padding: "3px 15px",
  },
}));
