import { createAsyncThunk } from "@reduxjs/toolkit";
import { TX_APPROVAL_STATUS } from "../../components/modals/approvalModal/constants";
import { TX_STATUS } from "../../components/modals/transactionModal/constants";
import { requestNft, updateDeliveryId, revertOrderApi } from "../../Http/api";
import { getPolygonTxFees } from "../../utils/utils";

export const orderNft = createAsyncThunk(
  "order/orderNft",
  async (payload, { dispatch, getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;
    const { web3Instance } = getState().Web3Instance;

    const {
      country,
      postalCode,
      contactNum,
      city,
      tokenId,
      price,
      ownerWallet,
      deliveryAddress,
      setTxInfo,
      ip,
    } = payload;
    try {
      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Transaction in progress please wait",
      });
      const fd = new FormData();
      fd.append("country", country);
      fd.append("postalCode", postalCode);
      fd.append("contactNum", contactNum);
      fd.append("city", city);
      fd.append("tokenId", tokenId);

      fd.append("price", price);
      fd.append("ownerWallet", ownerWallet);
      fd.append("deliveryAddress", deliveryAddress);
      fd.append("ipAddress", ip);

      const { data } = await requestNft(fd);

      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Please accept tx for approval from wallet",
      });

      const fullAddressFormated = deliveryAddress
        .split(" ")
        .join("")
        .toLocaleLowerCase();

      const proof = web3Instance.utils.soliditySha3(fullAddressFormated);

      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const deliveryCharges = await marketplaceInstance.methods
            .deliveryCharges()
            .call();

          const gasLimit = await marketplaceInstance.methods
            .createDeliveryOrder(tokenId, data.deliveryId, proof)
            .estimateGas({ from: connectedWallet, value: deliveryCharges });
          marketplaceInstance.methods
            .createDeliveryOrder(tokenId, data.deliveryId, proof)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
              value: deliveryCharges,
            })
            .once("transactionHash", function (txHash) {
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;
              const { deliveryOrderId } =
                receipt.events.DeliveryOrder.returnValues;

              try {
                const fd = new FormData();
                fd.append("orderDeliveryId", deliveryOrderId);
                fd.append("deliveryId", data.deliveryId);
                fd.append("transactionHash", txHash);

                const { apiResp } = await updateDeliveryId(fd);

                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt });
                // return apiResp;
              } catch (error) {
                reject(error);
              }
            })
            .on("error", reject);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      throw error;
    }
  }
);

export const revertOrder = createAsyncThunk(
  "order/revertOrder",
  async (payload, { dispatch, getState }) => {
    const { marketplaceInstance } = getState().Contracts;
    const { connectedWallet, connectedChainId } = getState().walletSlicer;
    const { web3Instance } = getState().Web3Instance;
    const { setTxInfo, tokenId, orderDeliveryId } = payload;

    try {
      setTxInfo({
        txStatus: TX_APPROVAL_STATUS.PENDING,
        txMessage: "Transaction in progress please wait",
      });

      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const { deliveryCharges } = await marketplaceInstance.methods
            .deliveryOrders(orderDeliveryId)
            .call();

          const gasLimit = await marketplaceInstance.methods
            .cancelDeliveryOrder(orderDeliveryId)
            .estimateGas({ from: connectedWallet, value: deliveryCharges });

          marketplaceInstance.methods
            .cancelDeliveryOrder(orderDeliveryId)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
              value: deliveryCharges,
            })
            .once("transactionHash", function (txHash) {
              // this block will be executed once the transaction has been accept from wallet by the user
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              const txHash = receipt.transactionHash;
              console.log("error");
              try {
                const fd = new FormData();

                fd.append("orderDeliveryId", orderDeliveryId);
                fd.append("tokenId", tokenId);

                const apiRes = await revertOrderApi(fd);
                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt, apiRes });
              } catch (err) {
                reject(err);
              }
            })
            .on("error", reject);
        } catch (err) {
          reject(err);
        }
      });
    } catch (error) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: error.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });
      throw error;
    }
  }
);
