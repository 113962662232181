import { combineReducers } from "@reduxjs/toolkit";
import LoginSlice from "./login/login.slicer";

import AllArts from "./arts/arts.slicer";
import singleNftDetails from "./singleNftDetails/singleNftDetails.slicer";
import FeatureArts from "./featuresNfts/featuresNfts.slicer";
import userProfile from "./userProfile/userProfile.slicer";
import web3Slicer from "./web3/web3.slicer";
import walletSlicer from "./wallet/wallet.slicer";
import authSlicer from "./auth/auth.slicer";
import editUserDetailsSlicer from "../redux/updateProfile/updateProfile.Slicer";
import headerSearch from "../redux/headerSearch/headerSearch.Slicer";
import contractSlicer from "../redux/contract/contract.slicer";
import mintSlice from "../redux/mint/mint.slice";
import Trade from "../redux/trade/trade.slicer";
import orderNftSlice from "../redux/nftOrder/nftOrder.slice";
import burnNftSlice from "../redux/burnNft/burnNft.slice";
import uploadImagesSlice from "../redux/uplaodRelatedImages/uploadImages.slice";
const rootReducer = combineReducers({
  LoginSlice,

  AllArts,
  singleNftDetails,
  FeatureArts,
  userProfile,
  Web3Instance: web3Slicer,
  walletSlicer,
  auth: authSlicer,
  editUserDetailsSlicer,
  HeaderSearch: headerSearch,
  Contracts: contractSlicer,
  mint: mintSlice,
  Trade,
  orderNft: orderNftSlice,
  burnNft: burnNftSlice,
  relatedImages: uploadImagesSlice,
});
export default rootReducer;
