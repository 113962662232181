import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CusAccordion = ({ title, details, logo }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.Accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <div className={classes.heading}>
          <Typography>{logo}</Typography>
          <Typography>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.MuiAccordionDetails}>
        <div>{details}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CusAccordion;
const useStyles = makeStyles((theme) => ({
  Accordion: {
    background: theme.palette.background.secondary,
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: "10px",

    "& p": {
      fontSize: "25px",
    },
    "& svg": {
      fontSize: "25px",
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  MuiAccordionDetails: {
    display: "block",
  },
}));
