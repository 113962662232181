import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import image from "../../assets/laptop.png";

export default function AboutSection() {
  const classes = useStyles();
  return (
    <div className={classes.mainSection}>
      {Data.map(({ heading, text }) => {
        return (
          <div className={classes.section}>
            <div className={classes.headtext}>
              <Typography variant="h3" className={classes.heading}>
                {heading}
              </Typography>
              <Typography variant="body1" className={classes.text}>
                {text}
              </Typography>
            </div>
            <div>
              <img src={image} alt="img" className={classes.image} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  mainSection: {
    backgroundColor: theme.palette.background.primary,

    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  section: {
    padding: "50px 0px",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: 90,
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  headtext: {
    display: "flex",

    flexDirection: "column",
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },

  image: {
    height: 200,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },

  text: {
    padding: " 20px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
}));

const Data = [
  {
    heading: " Who are we?",
    text: ` Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    image: image,
  },
  {
    heading: " Who are we?",
    text: ` Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    image: image,
  },
  {
    heading: " Who are we?",
    text: ` Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    image: image,
  },
  {
    heading: " Who are we?",
    text: ` Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
];
