import BurnBeforeLIsting from "./burnBeforeListingModal";
import BurnNft from "./burnNftModal";
import ListingModal from "./listingModal/listingModal";
import OrderNftModal from "./orderNftModal/orderNftModal";

import ReSellNftModal from "./resellNftModal";
import NftDelivery from "./viewNftModal";

const CardModals = ({ item, showModal, setShowModal }) => {
  return (
    <>
      <BurnNft
        details={item}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <NftDelivery
        details={item}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <OrderNftModal
        details={item}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ReSellNftModal
        details={item}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ListingModal
        details={item}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <BurnBeforeLIsting
        details={item}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default CardModals;
