import { create } from "ipfs-http-client";
const auth =
  "Basic " +
  Buffer.from(
    process.env.REACT_APP_INFURA_IPFS_PROJECT_ID +
      ":" +
      process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET
  ).toString("base64");

export const client = create({
  host: "ipfs.infura.io",
  port: "5001",
  protocol: "https",
  apiPath: "/api/v0",
  headers: {
    authorization: auth,
  },
});

export const uploadMetadataToIPFS = async (metadata) => {
  const metadataRes = await addToIPFS(null, metadata);
  return metadataRes.cid.toString();
};

export const uploadNftToIPFS = async (file, fileContent) => {
  const FILE_EXT = file.split(".").pop();
  const FILE_NAME = `nft.${FILE_EXT}`;
  const res = await addToIPFS(FILE_NAME, fileContent, true);
  return `ipfs://${res.cid.toString()}/${FILE_NAME}`; //URI
};

/**
 *
 * @param {String} fileName is the name of directory in which the file will be store
 * @param {any} content is the data to be stored
 * @returns Promise
 */
async function addToIPFS(fileName, content) {
  let res = await client.add(
    fileName
      ? {
          path: `/${fileName}`,
          content: content,
        }
      : {
          content: content,
        },
    {
      wrapWithDirectory: Boolean(fileName),
      progress: (len) => {
        console.log("Uploading to ipfs..." + len);
      },
    }
  );
  return res;
}
