import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import CustomButton from "../buttons";

const ShowUserActionBtns = ({
  isUser,
  isOwnerAdmin,
  handleOpenModal,
  isCollection,
  isOrders,
  artPrice,
  isOrderPlaced,
  RevertOrder,
}) => {
  const classes = useStyles();
  const { isAdmin } = useSelector((state) => state.walletSlicer);

  return (
    <>
      {isUser && isCollection && !isAdmin && (
        <div className={classes.priceBox}>
          {!Boolean(Number(isOrderPlaced)) && !Boolean(Number(artPrice)) && (
            <CustomButton
              variant="text"
              onClick={handleOpenModal}
              data-button-key="orderNftModal"
            >
              Order
            </CustomButton>
          )}

          {Boolean(Number(isOrderPlaced)) ? (
            <CustomButton variant="text">Order Already Placed</CustomButton>
          ) : (
            <CustomButton
              variant="contained"
              onClick={handleOpenModal}
              data-button-key="listingModal"
            >
              {artPrice ? "Updated or Cancel" : " List Nft"}
            </CustomButton>
          )}
        </div>
      )}

      {isUser && isOrders && (
        <div className={classes.adminOrder}>
          <div className={classes.priceBox}>
            <CustomButton
              variant="contained"
              onClick={handleOpenModal}
              data-button-key="viewNftModal"
            >
              View Nft
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={handleOpenModal}
              data-button-key="burnNftModal"
            >
              Burn Nft
            </CustomButton>
          </div>
          <div style={{ margin: "10px auto 0px auto" }}>
            <CustomButton
              variant="outlined"
              style={{ backgroundColor: "red" }}
              onClick={RevertOrder}
            >
              Rovoke Order
            </CustomButton>
          </div>
        </div>
      )}

      {isUser && isCollection && isAdmin && (
        <div className={classes.priceBox}>
          <CustomButton
            variant="contained"
            onClick={handleOpenModal}
            data-button-key="listingModal"
          >
            {artPrice ? "Updated or Cancel Listing" : " List Nft"}
          </CustomButton>

          {!artPrice && (
            <CustomButton
              variant="contained"
              onClick={handleOpenModal}
              data-button-key="burnBeforeListing"
            >
              Burn Nft
            </CustomButton>
          )}
        </div>
      )}
    </>
  );
};

export default ShowUserActionBtns;

const useStyles = makeStyles((theme) => ({
  priceBox: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },

  adminOrder: {
    display: "grid",
  },
}));
