import { whatIsFileType } from "../../helper";

const MediaRender = ({ artImg, nftImage, url }) => {
  return (
    <>
      {whatIsFileType(artImg) === "image" && (
        <img src={url} alt="displayed nft" className={nftImage} />
      )}
      {whatIsFileType(artImg) === "video" && (
        <video
          src={url}
          loop
          mute
          autoPlay
          playsInline
          controls
          className={nftImage}
        />
      )}
    </>
  );
};

export default MediaRender;
