import {
  Avatar,
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LaunchIcon from "@material-ui/icons/Launch";
import { artistActivityHistory } from "../../redux/userProfile/userProfile.actions";
import { getIpfsUrl } from "../../helper";
import { Link } from "react-router-dom";
import TimelineIcon from "@material-ui/icons/Timeline";

const ActivityTable = ({ wallet }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activityData, isActivityLoading } = useSelector(
    (state) => state.userProfile
  );

  useEffect(() => {
    dispatch(artistActivityHistory(wallet));
  }, [wallet, dispatch]);
  return (
    <div>
      {!activityData ? (
        <Typography variant="h2" align="center" style={{ color: "white" }}>
          No Current Activity!
        </Typography>
      ) : isActivityLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          {" "}
          <div className={classes.heading}>
            <Typography>
              <TimelineIcon />
            </Typography>
            <Typography>ACTIVITY</Typography>
          </div>
          <TableContainer className={classes.TableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>NFT</TableCell>
                  <TableCell className={classes.cell} align="center">
                    Transaction
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    By
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    Address
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    Price
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    Date
                  </TableCell>
                  <TableCell className={classes.cell} align="center">
                    TxHash
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activityData?.map(
                  (
                    {
                      transactionType,
                      username,
                      userWallet,
                      transactionAmount,
                      date,
                      transactionHash,
                      artImage,
                      artName,
                      tokenId,
                    },
                    i
                  ) => {
                    const dateString = new Date(date);
                    const imageUrl = getIpfsUrl(artImage);
                    return (
                      <TableRow key={i}>
                        <TableCell align="center">
                          <Box
                            component={Link}
                            to={`/nft-detail/${tokenId}`}
                            display={"flex"}
                            alignItems="center"
                            gridGap={5}
                            className={classes.artActivityDetail}
                          >
                            <Avatar src={imageUrl} alt={artName} />
                            {artName}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          {transactionType}
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          {username}
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          {userWallet.slice(0, 10) + "..."}
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          {transactionAmount}
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          {dateString.toLocaleDateString()}
                        </TableCell>
                        <TableCell className={classes.cell} align="center">
                          <a
                            href={`https://mumbai.polygonscan.com/tx/${transactionHash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LaunchIcon className={classes.icon} />
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default ActivityTable;
const useStyles = makeStyles((theme) => ({
  TableContainer: {
    width: "100%",
    background: theme.palette.background.primary,
    border: "1px solid white",
  },
  cell: {
    [theme.breakpoints.down("xs")]: {
      padding: 5,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  artActivityDetail: {
    color: theme.palette.text.primary,
  },
  heading: {
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    gap: 5,
  },
}));
