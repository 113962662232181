import { makeStyles, Typography } from "@material-ui/core";
import watch from "../assets/watch.png";
import banner from "../assets/bannerMain.png";
import FeaturedNfts from "../components/featuredNfts";
import nftBanner from "../assets/nftBanner.png";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { getFeatureArts } from "../redux/featuresNfts/featuresNfts.actions";
const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatureArts());
  }, [dispatch]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <Typography className={classes.title}>
          World’s first physical NFT <br /> collectible asset marketplace
        </Typography>
        <div className={classes.nftBox}>
          <img src={watch} alt="nft" className={classes.nftOne} />
          <img src={watch} alt="nft" className={classes.nftTwo} />
          <img src={watch} alt="nft" className={classes.nftThree} />
        </div>
      </div>
      <div>
        <FeaturedNfts />
      </div>
      <div className={classes.bannerBox}>
        <img className={classes.nftBanner} src={nftBanner} alt="banner" />
      </div>
    </div>
  );
};

export default Home;
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: theme.palette.background.primary,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "28px",
    alignItems: "center",
    height: "calc(100vh - 190px)",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: "38px",
    fontWeight: "800",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "22px",
    },
  },

  nftOne: {
    position: "absolute",
    right: "100px",
    top: "250px",

    [theme.breakpoints.down("md")]: {
      right: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "calc(50% - 150px)",
      left: "calc(50% + 20px)",
    },
  },
  nftTwo: {
    position: "absolute",
    top: "425px",
    right: "100px",
    [theme.breakpoints.down("md")]: {
      right: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "calc(50% + 40px)",
      left: "calc(50% + 20px)",
    },
  },
  nftThree: {
    position: "absolute",
    right: "275px",
    top: "340px",
    [theme.breakpoints.down("md")]: {
      top: "340px",
      right: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      // right: "0px",
      top: "calc(50% - 50px)",
      right: "calc(50% + 20px)",
    },
  },
  nftBanner: {
    width: "100%",
  },
  bannerBox: {
    padding: "100px 0",
  },
  nftBox: {
    overflow: "hidden",
  },
}));
