import { OwnRareURL } from "../../Http/config";

export const loginUser = async (payload) => {
  try {
    const res = await OwnRareURL.get("/users/loginWithWallet", {
      params: {
        wallet: payload,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
