import { createAsyncThunk } from "@reduxjs/toolkit";
import { TX_STATUS } from "../../components/modals/transactionModal/constants";
import { uploadMetadataToIPFS, uploadNftToIPFS } from "../../ipfs";
import { readFileAsync } from "../../helper";
import { mintNft } from "../../Http/api";
import { getPolygonTxFees } from "../../utils/utils";
/**
 * @dev this function will call the mint function.
 * @param payload the payload should have the following properties:
 * - NFT title
 * - description
 * - assets i.e. NFT image
 */
export const mint = createAsyncThunk(
  "mint/mint",
  async (payload, { dispatch, getState }) => {
    //  upload NFT image to ipfs

    const { file, setTxInfo } = payload;

    setTxInfo({
      txStatus: TX_STATUS.PENDING,
      txMessage: "Uploading NFT to IPFS",
    });
    // step # 1

    try {
      const fileBuffer = await readFileAsync(file);
      let bffr = await Buffer(fileBuffer);
      const NFT_URI = await uploadNftToIPFS(file.name, bffr);

      const { title, description } = payload;

      // step # 2 create and upload metadata to IPFS
      const metadata = JSON.stringify(
        {
          name: title,
          description,
          image: NFT_URI,
        },
        null,
        "\t"
      );

      const matadataURI = await uploadMetadataToIPFS(metadata);

      // step # 3

      setTxInfo({
        txStatus: TX_STATUS.PENDING,
        txMessage: "Please accept tx from wallet",
      });

      const { marketplaceInstance } = getState().Contracts;
      const { connectedWallet, connectedChainId } = getState().walletSlicer;

      return await new Promise(async (resolve, reject) => {
        try {
          const { maxFeePerGas, maxPriorityFeePerGas } = await getPolygonTxFees(
            connectedChainId
          );
          const gasLimit = await marketplaceInstance.methods
            .mint(matadataURI)
            .estimateGas({ from: connectedWallet });

          marketplaceInstance.methods
            .mint(matadataURI)
            .send({
              from: connectedWallet,
              maxPriorityFeePerGas,
              maxFeePerGas,
              gasLimit: gasLimit.toString(),
            })

            .once("transactionHash", function (txHash) {
              // this block will be executed once the transaction has been accept from wallet by the user
              setTxInfo({
                txStatus: TX_STATUS.PENDING,
                txMessage: "Please wait for tx to confirm by blockchain",
                txHash,
              });
            })
            .once("receipt", async (receipt) => {
              // this block will be executed once the transaction gets completed
              //  call the required backend  apis

              // after logic resolve

              const {
                user: { walletAddress },
              } = getState().auth;

              const txHash = receipt.transactionHash;
              const tokenId = parseInt(receipt.events[0].raw.topics[3]);

              const fd = new FormData();
              fd.append("artName", title);
              fd.append("description", description);
              fd.append("image", NFT_URI);
              fd.append("metadata", matadataURI);
              fd.append("tokenId", tokenId);

              fd.append("transactionHash", txHash);
              fd.append("walletAddress", walletAddress);

              try {
                const apiResponse = await mintNft(fd);

                setTxInfo({
                  txStatus: TX_STATUS.SUCCESS,
                  txMessage: "successful",
                  txHash,
                });
                resolve({ receipt, apiResponse }); // to mark the promise fulfilled
              } catch (err) {
                reject(err);
              }
            })

            // this block will be executed either  the transaction has been rejected by the user or the transaction has been reverted from contract
            .on("error", reject);
        } catch (e) {
          reject(e);
        }
      });
    } catch (err) {
      setTxInfo({
        txStatus: TX_STATUS.REJECTED,
        txMessage: err.message || "Transaction has been reverted by EVM.",
        txHash: null,
      });

      throw err;
    }
  }
);
