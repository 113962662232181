const reducers = {
  updateNetworkInfo: (state, action) => {
    state.connectedChainId = action.payload.connectedChainId;
    state.isCorrectNetwork = action.payload.isCorrectNetwork;
  },
  resetChainInfo: (state, action) => {
    state.connectedChainId = null;
    state.isCorrectNetwork = false;
    state.isChainInfoLoading = false;
    state.isChainInfoLoaded = false;
    state.isChainInfoLoadingFailed = false;
  },
};

export default reducers;
