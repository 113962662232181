import React from "react";

const SvgIcon = ({ search, circle, ...props }) => {
  if (search) {
    return (
      <svg
        {...props}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.964 18.4224C15.1863 19.8772 12.9138 20.75 10.4375 20.75C4.74206 20.75 0.125 16.1329 0.125 10.4375C0.125 4.74206 4.74206 0.125 10.4375 0.125C16.1329 0.125 20.75 4.74206 20.75 10.4375C20.75 12.9138 19.8772 15.1863 18.4224 16.964L24.573 23.1145C24.9757 23.5173 24.9757 24.1702 24.573 24.573C24.1702 24.9757 23.5173 24.9757 23.1145 24.573L16.964 18.4224ZM18.6875 10.4375C18.6875 14.9938 14.9938 18.6875 10.4375 18.6875C5.88115 18.6875 2.1875 14.9938 2.1875 10.4375C2.1875 5.88115 5.88115 2.1875 10.4375 2.1875C14.9938 2.1875 18.6875 5.88115 18.6875 10.4375Z"
          fill="#2278D4"
        />
      </svg>
    );
  } else if (circle) {
    return (
      <svg
        {...props}
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="26" cy="26" r="24.5" stroke="white" strokeWidth="3" />
      </svg>
    );
  }
};

export default SvgIcon;
